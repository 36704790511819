/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/AdminCustomButtons/Button.jsx";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import countriesList from "countries-list";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import _ from "lodash";
import "./style.scss";

const countryToFlag = isoCode => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
      .toUpperCase()
      .replace(/./g, char =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
};

function LanguageForm({ isEdit, collection, onChange, onSubmit, classes, addedLanguages }) {
  const handleFormSubmit = () => {
    onSubmit();
  };

  const handleChange = (e, value) => {
    onChange(value);
  };

  const getSubmitButton = () => {
    if (isEdit) {
      return <TranslationTextField value="Button.Update" defaultValue="Update" />;
    }
    return <TranslationTextField value="Button.Create" defaultValue="Create" />;
  }

  const countries = _.filter(_.map(countriesList.countries, (item, key) => ({
    ...item,
    key,
    title: item.name
  })), item => !_.find(collection, { key: item.key }) && !_.find(addedLanguages, { key: item.key }));

  return (
    <ValidatorForm
      style={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        margin: 0
      }}
      onSubmit={handleFormSubmit}
    >
      <div className="flex layout-row">
        <Button
          type="submit"
          color="primary"
          style={{ marginRight: 10, height: "3rem" }}
        >
          {getSubmitButton()}
        </Button>
        <Autocomplete
          multiple
          id="country-select-demo"
          style={{ width: 600 }}
          options={countries}
          onChange={(event, value) => handleChange(event, value)}
          classes={{
            option: classes.option
          }}
          getOptionLabel={option => option.name}
          renderOption={option => (
            <Fragment>
              <span>{countryToFlag(option.key)}</span>
              {option.name}
            </Fragment>
          )}
          renderInput={params => (
            <TextValidator
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </div>
    </ValidatorForm>
  );
}

LanguageForm.propTypes = {
  collection: PropTypes.array,
  addedLanguages: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

LanguageForm.dafaultProps = {
  collection: [],
  addedLanguages: [],
  onChange: () => false,
  onSubmit: () => false,
};

export default withStyles(styles)(LanguageForm);
