import { combineReducers } from "redux";
import translationsList from "./translations-list";
import translationForm from "./translation-form";
import modal from "./modal";
import notifications from "./notifications";
import authLogin from "./auth-login";
import authLogout from "./auth-logout";
import authRegister from "./auth-register";
import authSession from "./auth-session";
import documentTypeForm from "./document-type-form";
import documentTypeList from "./document-type-list";
import languageForm from "./language-form";
import languageList from "./language-list";
import multilanguageKeys from "./multinguage-keys";
import multilanguageList from "./multilanguage-list";
import multilanguageForm from "./multilanguage-form";
import multilanguageInlineForm from "./multilanguage-inline-form";
import translationLanguageForm from "./translation-language-form";
import translationLanguageList from "./translation-language-list";
import userStatystics from "./user-statystics";
import feedbackForm from "./feedback-form";
import feedbackById from "./feedback-by-id";

export default combineReducers({
  translationsList,
  translationForm,
  modal,
  notifications,
  authLogin,
  authLogout,
  authRegister,
  authSession,
  documentTypeForm,
  documentTypeList,
  languageForm,
  languageList,
  multilanguageKeys,
  multilanguageList,
  multilanguageForm,
  multilanguageInlineForm,
  translationLanguageForm,
  translationLanguageList,
  userStatystics,
  feedbackForm,
  feedbackById
});
