import AuthService from "../services/auth-service";

const authService = new AuthService();

export const PREFIX = "auth";
export const ACTION_LOGIN_REQ = `${PREFIX}.ACTION_LOGIN_REQ`;
export const ACTION_LOGIN_RES = `${PREFIX}.ACTION_LOGIN_RES`;
export const ACTION_LOGIN_ERR = `${PREFIX}.ACTION_LOGIN_ERR`;
export const ACTION_REGISTER_REQ = `${PREFIX}.ACTION_REGISTER_REQ`;
export const ACTION_REGISTER_RES = `${PREFIX}.ACTION_REGISTER_RES`;
export const ACTION_REGISTER_ERR = `${PREFIX}.ACTION_REGISTER_ERR`;
export const ACTION_LOGOUT_REQ = `${PREFIX}.ACTION_LOGOUT_REQ`;
export const ACTION_LOGOUT_RES = `${PREFIX}.ACTION_LOGOUT_RES`;
export const ACTION_LOGOUT_ERR = `${PREFIX}.ACTION_LOGOUT_ERR`;
export const ACTION_SAVE_SESSION = `${PREFIX}.ACTION_SAVE_SESSION`;
export const ACTION_CREATE_SESSION = `${PREFIX}.ACTION_CREATE_SESSION`;
export const ACTION_SET_LOCALE = `${PREFIX}.ACTION_SET_LOCALE`;
export const ACTION_SET_TRANSLATE_UI_MODE = `${PREFIX}.ACTION_SET_TRANSLATE_UI_MODE`;
export const DESTROY = `${PREFIX}.DESTROY`;

function ActionAuth() {
  return {
    login,
    logout,
    register,
    saveSession,
    getSession,
    setLocale,
    setTranslateUiMode,
    createSession
  };
  function saveSession(payload) {
    return async dispatch => {
      dispatch({ type: ACTION_SAVE_SESSION, payload });
    };
  }

  function getSession() {
    return async dispatch => {
      dispatch({ type: ACTION_LOGIN_REQ });
      try {
        const payload = await authService.getSession();
        if (!payload.message) {
          dispatch({ type: ACTION_SAVE_SESSION, payload: { user: payload } });
        }
      } catch (err) {
        dispatch({ type: ACTION_LOGIN_ERR, payload: err });
      }
    };
  }

  function createSession() {
    return async dispatch => {
      dispatch({ type: ACTION_CREATE_SESSION });
      try {
        await authService.createSession();
      } catch (err) {
        dispatch({ type: ACTION_LOGIN_ERR, payload: err });
      }
    };
  }

  function login({ email, password }) {
    return async dispatch => {
      dispatch({ type: ACTION_LOGIN_REQ });
      try {
        const payload = await authService.logIn({ email, password });
        dispatch({ type: ACTION_SAVE_SESSION, payload });
      } catch (err) {
        dispatch({ type: ACTION_LOGIN_ERR, payload: err });
      }
    };
  }

  function register({ email, password, companyName }) {
    return async dispatch => {
      dispatch({ type: ACTION_REGISTER_REQ });
      try {
        const payload = await authService.register({
          email,
          password,
          companyName
        });
        dispatch({ type: ACTION_REGISTER_RES, payload });
      } catch (err) {
        dispatch({ type: ACTION_REGISTER_ERR, payload: err });
      }
    };
  }

  function logout() {
    return async dispatch => {
      dispatch({ type: ACTION_LOGOUT_REQ });
      try {
        await authService.logout();
        const payload = {};
        dispatch({ type: ACTION_LOGOUT_RES, payload });
      } catch (err) {
        dispatch({ type: ACTION_LOGOUT_ERR, payload: err });
      }
    };
  }

  function setLocale(payload) {
    return async dispatch => {
      dispatch({ type: ACTION_SET_LOCALE, payload });
      window.location.reload();
    };
  }

  function setTranslateUiMode() {
    return async dispatch => {
      dispatch({ type: ACTION_SET_TRANSLATE_UI_MODE });
    };
  }
}

export default ActionAuth();
