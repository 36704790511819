import { API_URL } from "../constants";
import axios from "axios";

export const post = formData => {
  return axios
    .post(`${API_URL}/feedback/post`, formData, { withCredentials: true })
    .then(response => response.data);
};

export const fetch = () => {
  return axios
    .post(`${API_URL}/feedback/fetch`, null, { withCredentials: true })
    .then(response => response.data);
};

export const fetchById = id => {
  return axios
    .post(`${API_URL}/feedback/fetch-by-id`, { id }, { withCredentials: true })
    .then(response => response.data);
};
