export const PREFIX = "notifications";
export const ACTION_NOTIFICATIONS_DESTROY = `${PREFIX}.ACTION_NOTIFICATIONS_DESTROY`;
export const ACTION_NOTIFICATIONS_UPDATE = `${PREFIX}.ACTION_NOTIFICATIONS_UPDATE`;
export const ACTION_NOTIFICATIONS_ERR = `${PREFIX}.ACTION_NOTIFICATIONS_ERR`;

function ActionNotifications() {
  return {
    update,
    destroy
  };

  function update(payload) {
    return {
      type: ACTION_NOTIFICATIONS_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_NOTIFICATIONS_DESTROY };
  }
}

export default ActionNotifications();
