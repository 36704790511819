import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";

import "./style.scss";

export default function AlertDialog({ open, children, onClose, style }) {
  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modal_container"
      maxWidth={false}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none"
        }
      }}
    >
      <div className="modal" style={style}>
        {children}
      </div>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired,
  style: PropTypes.object
};

AlertDialog.defaultProps = {
  open: false,
  onClose: () => false,
  style: {}
};
