import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Loader from "../Loader/Loader";
import Number from "components/format/Number";
import Percent from "components/format/Percent";
import Currency from "components/format/Currency";
import Date from "components/format/Date";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
// core components
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

import _ from "lodash";
import "./styles.scss";

function DataTable({
  classes,
  tableHead,
  tableData,
  tableHeaderColor,
  isLoading,
  onTableEvent,
  user
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  const handleClick = (eventId, id) => {
    onTableEvent(eventId, id);
  };

  const getDataType = (column, row) => {
    const value = row[column.id];
    switch (column.type) {
      case "counter":
      case "string":
        if (column.translatable && value) {
          return (
            <TranslationTextField
              value={value}
              defaultValue={value}
              hideMinus
            />
          );
        }
        return value;
      case "icon":
        return (
          <div className="table_icon">
            <i className={`${column.icon || value}`} aria-hidden="true" />
          </div>
        );
      case "number":
        return (
          <Number value={value} isDecimalDisabled={column.isDecimalDisabled} />
        );
      case "currency":
        return (
          <Currency
            value={value}
            isDecimalDisabled={column.isDecimalDisabled}
          />
        );
      case "percent":
        return (
          <Percent value={value} isDecimalDisabled={column.isDecimalDisabled} />
        );
      case "date":
        return <Date value={value} />;
      default:
        return value;
    }
  };

  const renderRows = () => {
    return tableData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(row => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={row.nr}>
            {renderCells(row)}
          </TableRow>
        );
      });
  };

  const camelCase = str => {
    if (!str || typeof str !== "string") {
      return "";
    }
    return str
      .replace(/\s(.)/g, a => a.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, b => b.toLowerCase());
  };

  const renderCells = row => {
    return tableHead.map(column => {
      if (
        !column.adminOnly ||
        _.get(user, "role") === "admin" ||
        _.get(user, "role") === "superadmin"
      ) {
        return (
          <TableCell
            className={`${classes.tableCell} table-row-cell `}
            key={column.id}
            align={column.align || "left"}
            onClick={() => handleClick(column.event, row.id)}
          >
            {getDataType(column, row)}
          </TableCell>
        );
      }
      return null;
    });
  };

  if (isLoading) {
    return <Loader />;
  }
  if (!isLoading && (!_.size(tableHead) || !_.size(tableData))) {
    return (
      <TranslationTextField
        value="DataTable.NoData"
        defaultValue="No data yet"
      />
    );
  }

  return (
    <div className={classes.tableResponsive} style={{ overflowX: "visible" }}>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map(column => {
                if (
                  !column.adminOnly ||
                  _.get(user, "role") === "admin" ||
                  _.get(user, "role") === "superadmin"
                ) {
                  return (
                    <TableCell
                      className={`${classes.tableCell} ${classes.tableHeadCell} table-header-cell`}
                      key={column.id}
                      align={column.align || "left"}
                      width={column.size}
                    >
                      <TranslationTextField
                        value={`Column.${camelCase(column.name)}`}
                        defaultValue={column.name}
                      />
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </div>
  );
}

DataTable.defaultProps = {
  tableHeaderColor: "gray",
  isLoading: false,
  onTableEvent: () => false,
  user: null
};

DataTable.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.array,
  tableData: PropTypes.array,
  onTableEvent: PropTypes.func,
  user: PropTypes.object
};

export default withStyles(tableStyle)(DataTable);
