import React, { PureComponent } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

import snackbarContentStyle from "assets/jss/material-kit-react/components/snackbarContentStyle.jsx";

import "./style.scss";

class SnackbarContent extends PureComponent {
  getContent = () => {
    const { classes, message, color, close, icon } = this.props;
    var action = [];
    if (close !== undefined) {
      action = [
        <IconButton
          className={classes.iconButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.closeAlert}
        >
          <Close className={classes.close} />
        </IconButton>
      ];
    }
    let snackIcon = null;
    switch (typeof icon) {
      case "function":
        snackIcon = <this.props.icon className={classes.icon} />;
        break;
      case "string":
        snackIcon = <Icon className={classes.icon}>{this.props.icon}</Icon>;
        break;
      default:
        snackIcon = null;
        break;
    }
    return (
      <Snack
        message={
          <div className="layout-row">
            {snackIcon}
            {message}
            {close !== undefined ? action : null}
          </div>
        }
        classes={{
          root: classes.root + " " + classes[color],
          message: classes.message + " " + classes.container
        }}
      />
    );
  };

  closeAlert = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    return this.getContent();
  }
}

SnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  close: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onClose: PropTypes.func
};

export default withStyles(snackbarContentStyle)(SnackbarContent);
