import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";

import { Link } from "react-router-dom";

function AdminFooter({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link to={"/"} className={classes.block}>
                Home
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to={"/about"} className={classes.block}>
                About us
              </Link>
            </ListItem>
            {/* TODO: Cleanup */}
            {/* <ListItem className={classes.inlineBlock}>
              <Link to={"/feedback"} className={classes.block}>
                Feedback
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to={"/licenses"} className={classes.block}>
                Licenses
              </Link>
            </ListItem> */}
          </List>
        </div>
        <p className={classes.right}>
          {/* <span>© 2019 www.verifytranslation.com All Rights Reserved</span> */}
        </p>
      </div>
    </footer>
  );
}

AdminFooter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(AdminFooter);
