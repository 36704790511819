/* eslint-disable react/prop-types */
import React, { useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import MuiAlert from "@material-ui/lab/Alert";

import { ActionFeedbackForm } from "../../actions/index";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import _ from "lodash";

import "./style.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Feedback({ classes, update, post, formData, message }) {
  const [errorMessage, setErrorMessage] = useState(false);
  const handleFormChange = name => event => {
    const clonedFormData = _.cloneDeep(formData);
    clonedFormData[name] = event.target.value;
    update(clonedFormData);
  };

  const handleFormSubmit = () => {
    if (!_.get(formData, "message")) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      post();
    }
  };

  return (
    <div className="flex layout-column feedback-form">
      <GridContainer>
        <GridItem className="animated fadeIn" xs={12} sm={12} md={12}>
          <div className={classes.brand}>
            <h1 className={classes.title}>
              <TranslationTextField
                value="FeedbackPage.ContactUs"
                defaultValue="Contact Us"
              />
            </h1>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card className="animated fadeIn">
            <ValidatorForm className={classes.form} onSubmit={handleFormSubmit}>
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4>
                  <TranslationTextField
                    value="FeedbackPage.FormTitle"
                    defaultValue="Please contact us directly by feedback form."
                  />
                </h4>
              </CardHeader>
              <CardBody>
                <div className="input-container">
                  <TextValidator
                    label={
                      <TranslationTextField
                        value="LoginPage.LabelEmail"
                        defaultValue="Mail address"
                      />
                    }
                    id="email"
                    onChange={handleFormChange("email")}
                    fullWidth={true}
                    value={_.get(formData, "email") || ""}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "This field is required",
                      "Value must be email"
                    ]}
                    type="email"
                  />
                </div>
                <div className="input-container flex layout-column">
                  <TextareaAutosize
                    rowsMin={5}
                    className="feedback-form_textarea"
                    aria-label="maximum height"
                    placeholder="Message"
                    onChange={handleFormChange("message")}
                    value={_.get(formData, "message") || ""}
                    style={{
                      borderColor: errorMessage ? "red" : "rgba(0,0,0,0.4)"
                    }}
                  />
                  {errorMessage ? (
                    <div className="feedback-form_error">
                      <TranslationTextField
                        value="LoginPage.ThisFieldIsRequired"
                        defaultValue="This field is required"
                      />
                    </div>
                  ) : null}
                </div>
                {message ? (
                  <Alert severity="success">
                    <TranslationTextField
                      value="LoginPage.SuccessMessage"
                      defaultValue={message}
                    />
                  </Alert>
                ) : null}
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button type="submit" simple color="primary" size="lg">
                  <TranslationTextField
                    value="FeedbackPage.Send"
                    defaultValue="Send"
                  />
                </Button>
              </CardFooter>
            </ValidatorForm>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = ({ feedbackForm }) => {
  return feedbackForm;
};

const mapDispatchToProps = {
  post: ActionFeedbackForm.post,
  update: ActionFeedbackForm.update
};

export default withStyles(componentsStyle)(
  connect(mapStateToProps, mapDispatchToProps)(Feedback)
);
