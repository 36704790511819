import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Modal from "../../components/Modal/Modal.jsx";
import GridItem from "components/AdminGrid/GridItem.jsx";
import GridContainer from "components/AdminGrid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardIcon from "components/AdminCard/CardIcon.jsx";
import CardFooter from "components/AdminCard/CardFooter.jsx";
import FeedbackView from "containers/feedback-view/FeedbackView.jsx";
import ActionUserStatystics from "../../actions/user-statystics";
import { ActionModal } from "../../actions/index";
import { connect } from "react-redux";

import sessionsColumns from "./sessionsColumns";
import verificationsColumns from "./verificationsColumns";
import feedbackColumns from "./feedbackColumns";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import _ from "lodash";

function Dashboard({
  classes,
  fetchStatystics,
  data,
  openModal,
  open,
  modalId
}) {
  const [feedbackId, setFeedbackId] = useState(null);
  useEffect(() => {
    fetchStatystics();
  }, []);

  const handleFeedbackModal = () => {
    if (open) {
      setFeedbackId(null);
    }
    openModal({ open: !open, modalId: "feedbackView" });
  };

  const handleTableEvent = (eventId, id) => {
    switch (eventId) {
      case "showMessage":
        setFeedbackId(id);
        handleFeedbackModal();
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>
                <TranslationTextField
                  value="Dashboard.AllVisits"
                  defaultValue="All visits"
                />
              </p>
              <h3 className={classes.cardTitle}>
                {_.size(_.get(data, "allVisits"))}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                <TranslationTextField
                  value="Dashboard.LastVisit"
                  defaultValue="Last visit"
                />
                {_.get(data, "lastVisit.datetime")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <GroupAddIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                <TranslationTextField
                  value="Dashboard.TodayVisits"
                  defaultValue="Today visits"
                />
              </p>
              <h3 className={classes.cardTitle}>
                {_.size(_.get(data, "todayVisits"))}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                <TranslationTextField
                  value="Dashboard.LastVisit"
                  defaultValue="Last visit"
                />
                {_.get(data, "lastVisit.datetime")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <PeopleIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                <TranslationTextField
                  value="Dashboard.AllSessions"
                  defaultValue="All sessions"
                />
                <h3 className={classes.cardTitle}>
                  {_.size(_.get(data, "allSessions"))}
                </h3>
              </p>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {_.get(data, "lastSession.user")}{" "}
                {_.get(data, "lastSession.datetime")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <PersonIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                <TranslationTextField
                  value="Dashboard.TodaySessions"
                  defaultValue="Today sessions"
                />
              </p>
              <h3 className={classes.cardTitle}>
                {_.size(_.get(data, "todaySessions"))}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {_.get(data, "lastSession.user")}{" "}
                {_.get(data, "lastSession.datetime")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <DoneAllIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                <TranslationTextField
                  value="Dashboard.AllVerifiedTranslations"
                  defaultValue="All verified translations"
                />
              </p>
              <h3 className={classes.cardTitle}>
                {_.size(_.get(data, "allVerifications"))}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                <TranslationTextField
                  value="Dashboard.LastVerification"
                  defaultValue="Last verification:"
                />
                {_.get(data, "lastVerification.datetime")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <DoneIcon />
              </CardIcon>
              <p className={classes.cardCategory}>
                <TranslationTextField
                  value="Dashboard.TodayVerifiedTranslations"
                  defaultValue="Today verified translations"
                />
              </p>
              <h3 className={classes.cardTitle}>
                {_.size(_.get(data, "todayVerifications"))}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                <TranslationTextField
                  value="Dashboard.LastVerification"
                  defaultValue="Last verification:"
                />
                {_.get(data, "lastVerification.datetime")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "Verifications",
              tabIcon: DoneAllIcon,
              tabContent: (
                <Table
                  tableHeaderColor="info"
                  tableHead={verificationsColumns}
                  tableData={_.get(data, "allVerifications")}
                />
              )
            },
            {
              tabName: "Sessions and visits",
              tabIcon: GroupAddIcon,
              tabContent: (
                <Table
                  tableHeaderColor="success"
                  tableHead={sessionsColumns}
                  tableData={_.get(data, "allSessionsAndVisits")}
                />
              )
            },
            {
              tabName: "Feedback",
              tabIcon: FeedbackIcon,
              tabContent: (
                <Table
                  tableHeaderColor="warning"
                  tableHead={feedbackColumns}
                  tableData={_.get(data, "feedback")}
                  onTableEvent={handleTableEvent}
                />
              )
            }
          ]}
        />
      </GridContainer>
      <Modal
        onClose={handleFeedbackModal}
        open={open && modalId === "feedbackView"}
        style={{ minWidth: "50rem" }}
      >
        <FeedbackView feedbackId={feedbackId} />
      </Modal>
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchStatystics: PropTypes.func.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool,
  openModal: PropTypes.func,
  modalId: PropTypes.string
};

const mapStateToProps = ({ userStatystics, modal }) => {
  return {
    ...userStatystics,
    open: modal.open,
    modalId: modal.modalId
  };
};

const mapDispatchToProps = {
  fetchStatystics: ActionUserStatystics.fetch,
  openModal: ActionModal.update
};

export default withStyles(dashboardStyle)(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
