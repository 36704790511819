import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "moment/min/locales";

export default function Date({ value }) {
  if (!value) {
    return "-";
  }
  const formatedValue = moment(value)
    .local()
    .format("L");

  return <span title={formatedValue}>{formatedValue}</span>;
}

Date.propTypes = {
  value: PropTypes.any
};
Date.defaultProps = {
  value: moment()
};
