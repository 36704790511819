/* eslint-disable react/prop-types */
import React from "react";
import Globe from "./spinningGlobe.gif";
import Check from "@material-ui/icons/Check";
import { connect } from "react-redux";
import _ from "lodash";
import "./style.scss";

import ActionModal from "../../actions/modal";
import ActionLanguageList from "../../actions/language-list";
import ActionMultilanguageForm from "../../actions/multilanguage-form";
import ActionNotifications from "../../actions/notifications";

import moment from "moment";

function TranslationNotifications({
  data,
  multilanguageKeys,
  openModal,
  open,
  update,
  translateUiMode,
  user,
  updateMessages,
  messages
}) {
  const translatedTo = _.toUpper(data.translatedTo) || "GB";
  const message = _.find(
    messages,
    item => _.get(item, "data.translationId") === data.translationId
  );

  const textTitle =
    "Please copy-paste the following text at the end of translation:";
  const textIdTitle = "Unique ID:";
  const value1 = `${_.get(multilanguageKeys[textIdTitle], translatedTo) ||
    textIdTitle}`;
  const value2 = `${_.get(multilanguageKeys[textTitle], translatedTo) ||
    textTitle}`;
  const text =
    "The translation may be verified at www.verifytranslation.com. Please enter the following ID No.";

  const value3 = `${_.get(multilanguageKeys[text], translatedTo) || text}`;
  const text2 = "and date";
  const value4 = `${_.get(multilanguageKeys[text2], translatedTo) || text2}`;

  const handleMultiEditTranslationModal = text => {
    update({ uniqueKey: text, ...multilanguageKeys[text] });
    openModal({ open: !open, modalId: "translationsEditForm" });
  };

  const handleCopy = item => {
    const input = document.createElement("input");
    input.value = `${value3} ${data.translationId} ${value4} ${moment(
      data.translationDate
    )
      .locale(getMomentLocale())
      .format("L")}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    const mess = [...messages];
    _.map(mess, message => {
      message.isCopied = false;
      if (message.title === item.title) {
        message.isCopied = true;
      }
    });
    updateMessages(mess);
  };

  const getMomentLocale = () => {
    switch (data.translatedTo || "GB") {
      case "GB":
        return "en-gb";
      case "US":
        return "en-us";
      case "LT":
        return "lt";
      case "RU":
        return "ru";
      default:
        return data.translatedTo.toLowerCase();
    }
  };

  const getCopyBtn = item => {
    if (_.get(item, "isCopied")) {
      return (
        <div className="animated fadeIn">
          <Check />
        </div>
      );
    }
    return <span onClick={() => handleCopy(item)}>Copy</span>;
  };
  const getCopyContent = () => {
    const copyContent = (
      <span className="translation-notification__translated-text">
        {value3}
        {user &&
        (user.role === "admin" || user.role === "superadmin") &&
        translateUiMode ? (
          <span className="translation-notification__globe">
            <img
              onClick={() => handleMultiEditTranslationModal(text)}
              src={Globe}
              className="translation-notification__globe-img"
              alt="globe"
            />
          </span>
        ) : null}
      </span>
    );
    const copyContent2 = (
      <span className="translation-notification__translated-text">
        {value4}
        {user &&
        (user.role === "admin" || user.role === "superadmin") &&
        translateUiMode ? (
          <span className="translation-notification__globe">
            <img
              onClick={() => handleMultiEditTranslationModal(text2)}
              src={Globe}
              className="translation-notification__globe-img"
              alt="globe"
            />
          </span>
        ) : null}
      </span>
    );
    return (
      <div className="notifications-container_notification-copy-content layout-row">
        <span className="translation-notification__translated-text">
          <b>
            {copyContent} {data.translationId} {copyContent2}{" "}
            {moment(data.translationDate)
              .locale(getMomentLocale())
              .format("L")}
          </b>
        </span>
        <span className="notifications-container_notification-copy-button layout-column">
          {getCopyBtn(message)}
        </span>
      </div>
    );
  };
  return (
    <span>
      <b className="translation-notification__translated-text">
        {value1} {data.translationId}
        {user &&
        (user.role === "admin" || user.role === "superadmin") &&
        translateUiMode ? (
          <span className="translation-notification__globe">
            <img
              onClick={() => handleMultiEditTranslationModal(textIdTitle)}
              src={Globe}
              className="translation-notification__globe-img"
              alt="globe"
            />
          </span>
        ) : null}
      </b>
      <p className="translation-notification__translated-text">
        {value2}
        {user &&
        (user.role === "admin" || user.role === "superadmin") &&
        translateUiMode ? (
          <span className="translation-notification__globe">
            <img
              onClick={() => handleMultiEditTranslationModal(textTitle)}
              src={Globe}
              className="translation-notification__globe-img"
              alt="globe"
            />
          </span>
        ) : null}
      </p>
      {getCopyContent()}
    </span>
  );
}

const mapStateToProps = ({
  multilanguageKeys,
  authSession,
  multilanguageInlineForm,
  modal,
  notifications
}) => {
  return {
    ...authSession,
    messages: _.get(notifications, "messages"),
    formData: _.get(multilanguageInlineForm, "formData"),
    multilanguageKeys: _.get(multilanguageKeys, "collection"),
    locale: _.get(authSession, "locale"),
    user: _.get(authSession, "session.user"),
    open: modal.open,
    modalId: modal.modalId,
    translateUiMode: _.get(authSession, "translateUiMode")
  };
};

const mapDispatchToProps = {
  fetch: ActionLanguageList.fetch,
  remove: ActionLanguageList.remove,
  destroy: ActionLanguageList.destroy,
  update: ActionMultilanguageForm.update,
  post: ActionMultilanguageForm.post,
  openModal: ActionModal.update,
  updateMessages: ActionNotifications.update
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationNotifications);
