import {
  ACTION_FEEDBACK_BY_ID_REQ,
  ACTION_FEEDBACK_BY_ID_RES,
  ACTION_FEEDBACK_BY_ID_ERR,
  ACTION_FEEDBACK_BY_ID_DESTROY
} from "../actions/feedback-by-id";

const initialState = {
  data: {},
  error: false,
  loading: false
};

const feedbackById = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FEEDBACK_BY_ID_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_FEEDBACK_BY_ID_RES:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ACTION_FEEDBACK_BY_ID_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_FEEDBACK_BY_ID_DESTROY:
      return {
        data: {},
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default feedbackById;
