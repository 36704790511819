// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import GavelIcon from "@material-ui/icons/Gavel";
import LanguageIcon from "@material-ui/icons/Language";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import TranslateIcon from "@material-ui/icons/Translate";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
// import UserProfile from "views/UserProfile/UserProfile.jsx";
import Translation from "views/Translation/Translation.jsx";
import Translations from "views/Translations/Translations.jsx";
import TranslationsByUser from "views/TranslationsByUser/TranslationsByUser.jsx";
import DocumentTypesPage from "views/DocumentTypesPage/DocumentTypesPage.jsx";
import LanguagePage from "views/LanguagePage/LanguagePage.jsx";
import MultilanguagePage from "views/MultilanguagePage/MultilanguagePage.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    translationKey: "SidebarLink.Dashboard",
    adminOnly: true
  },
  {
    path: "/translation-create",
    name: "New translation",
    icon: FiberNewIcon,
    component: Translation,
    layout: "/admin",
    translationKey: "SidebarLink.TranslationCreate"
  },
  {
    path: "/translations",
    name: "Translations list",
    icon: AssignmentIcon,
    component: Translations,
    layout: "/admin",
    translationKey: "SidebarLink.TranslationList",
    adminOnly: true
  },
  {
    path: "/my-translations",
    name: "My translations",
    icon: AssignmentIndIcon,
    component: TranslationsByUser,
    layout: "/admin",
    translationKey: "SidebarLink.MyTranslations"
  },
  {
    path: "/document-types",
    name: "Document types",
    icon: GavelIcon,
    component: DocumentTypesPage,
    layout: "/admin",
    translationKey: "SidebarLink.DocumentTypes"
  },
  {
    path: "/languages",
    name: "Languages",
    icon: LanguageIcon,
    component: LanguagePage,
    layout: "/admin",
    translationKey: "SidebarLink.Languages"
  },
  {
    path: "/multilanguage",
    name: "Multilanguage",
    icon: TranslateIcon,
    component: MultilanguagePage,
    layout: "/admin",
    translationKey: "SidebarLink.Multilanguage",
    superAdminOnly: true
  }
  // {
  //   path: "/user",
  //   name: "Profile",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin",
  //   translationKey: "SidebarLink.Profile"
  // }
];

// TODO: cleanup

export default dashboardRoutes;
