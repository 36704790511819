import {
  ACTION_USER_STATYSTICS_REQ,
  ACTION_USER_STATYSTICS_RES,
  ACTION_USER_STATYSTICS_ERR,
  ACTION_USER_STATYSTICS_UPDATE,
  ACTION_USER_STATYSTICS_DESTROY
} from "../actions/user-statystics";

const initialState = {
  data: {},
  error: false,
  loading: false
};

const userStatystics = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_USER_STATYSTICS_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_USER_STATYSTICS_RES:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ACTION_USER_STATYSTICS_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_USER_STATYSTICS_UPDATE:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case ACTION_USER_STATYSTICS_DESTROY:
      return {
        data: {},
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default userStatystics;
