/* eslint-disable react/prop-types */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

import Feedback from "containers/feedback/Feedback";

function FeedbackPage({ classes }) {
  return (
    <div className="feedback-page">
      <Header
        brand="Verify Translation"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <Parallax image={require("assets/img/stamp.jpg")}>
        <div className={classes.container}>
          <Feedback />
        </div>
      </Parallax>
      <Footer />
    </div>
  );
}

export default withStyles(componentsStyle)(FeedbackPage);
