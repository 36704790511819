/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import Table from "components/Table/Table";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardBody from "components/AdminCard/CardBody.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import columns from "./columns";
import ActionTranslationLanguageList from "../../actions/translation-language-list";
import { connect } from "react-redux";

import _ from "lodash";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function TranslationLanguageList({
  classes,
  loading,
  collection,
  fetch,
  remove,
  user
}) {
  useEffect(() => {
    fetch();
  }, []);

  const handleTableEvent = (eventId, id) => {
    switch (eventId) {
      case "deleteItem":
        remove(id);
        break;
      default:
        break;
    }
  };

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          <TranslationTextField
            value="TranslationLanguageList.Title"
            defaultValue="Translation languages"
          />
        </h4>
      </CardHeader>
      <CardBody>
        <Table
          isLoading={loading}
          tableHeaderColor="primary"
          tableHead={columns}
          tableData={collection}
          onTableEvent={handleTableEvent}
          user={user}
        />
      </CardBody>
    </Card>
  );
}

const mapStateToProps = ({ translationLanguageList, authSession }) => {
  return {
    ...translationLanguageList,
    user: _.get(authSession.session, "user")
  };
};

const mapDispatchToProps = {
  fetch: ActionTranslationLanguageList.fetch,
  remove: ActionTranslationLanguageList.remove,
  destroy: ActionTranslationLanguageList.destroy
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TranslationLanguageList)
);
