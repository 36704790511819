import { fetchById } from "../services/feedback";

export const PREFIX = "feedbackById";
export const ACTION_FEEDBACK_BY_ID_REQ = `${PREFIX}.ACTION_FEEDBACK_BY_ID_REQ`;
export const ACTION_FEEDBACK_BY_ID_RES = `${PREFIX}.ACTION_FEEDBACK_BY_ID_RES`;
export const ACTION_FEEDBACK_BY_ID_ERR = `${PREFIX}.ACTION_FEEDBACK_BY_ID_ERR`;
export const ACTION_FEEDBACK_BY_ID_DESTROY = `${PREFIX}.ACTION_FEEDBACK_BY_ID_DESTROY`;

function ActionFeedbackById() {
  return {
    destroy,
    fetch
  };

  function fetch(id) {
    return async dispatch => {
      dispatch({ type: ACTION_FEEDBACK_BY_ID_REQ });
      try {
        const payload = await fetchById(id);
        dispatch({ type: ACTION_FEEDBACK_BY_ID_RES, payload });
      } catch (err) {
        dispatch({ type: ACTION_FEEDBACK_BY_ID_ERR, payload: err });
      }
    };
  }

  function destroy() {
    return { type: ACTION_FEEDBACK_BY_ID_DESTROY };
  }
}

export default ActionFeedbackById();
