import { API_URL } from "../constants";
import axios from "axios";

export default class TranslationService {
  async fetch() {
    return axios
      .get(`${API_URL}/translation/fetch`, {
        withCredentials: true
      })
      .then(response => response.data);
  }

  async fetchByUser(user) {
    if (!user) {
      return false;
    }
    return axios
      .get(`${API_URL}/translation/fetch-by-user/${user}`, {
        withCredentials: true
      })
      .then(response => response.data);
  }

  async post(data) {
    if (!data) {
      return false;
    }
    return axios
      .post(`${API_URL}/translation/create`, data, { withCredentials: true })
      .then(response => response.data);
  }

  async verify(data) {
    if (!data) {
      return false;
    }
    return axios
      .post(`${API_URL}/translation/verify`, data, { withCredentials: true })
      .then(response => response.data);
  }

  async remove(data) {
    if (!data) {
      return false;
    }
    return axios
      .post(`${API_URL}/translation/remove`, data, { withCredentials: true })
      .then(response => response.data);
  }
}
