import { post as postDocType } from "../services/document-type-service";

export const PREFIX = "documentTypeForm";
export const ACTION_DOCUMENT_TYPE_FORM_REQ = `${PREFIX}.ACTION_DOCUMENT_TYPE_FORM_REQ`;
export const ACTION_DOCUMENT_TYPE_FORM_RES = `${PREFIX}.ACTION_DOCUMENT_TYPE_FORM_RES`;
export const ACTION_DOCUMENT_TYPE_FORM_ERR = `${PREFIX}.ACTION_DOCUMENT_TYPE_FORM_ERR`;
export const ACTION_DOCUMENT_TYPE_FORM_DESTROY = `${PREFIX}.ACTION_DOCUMENT_TYPE_FORM_DESTROY`;
export const ACTION_DOCUMENT_TYPE_FORM_UPDATE = `${PREFIX}.ACTION_DOCUMENT_TYPE_FORM_UPDATE`;

function ActionDocumentTypeForm() {
  return {
    update,
    destroy,
    post
  };

  function post() {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION_DOCUMENT_TYPE_FORM_REQ });
      try {
        const {
          documentTypeForm: { formData }
        } = getState();
        await postDocType(formData);
        dispatch({ type: ACTION_DOCUMENT_TYPE_FORM_DESTROY });
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION_DOCUMENT_TYPE_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_DOCUMENT_TYPE_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_DOCUMENT_TYPE_FORM_DESTROY };
  }
}

export default ActionDocumentTypeForm();
