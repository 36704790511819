import { postInline as postMultilang } from "../services/multilanguage-service";
import _ from "lodash";

export const PREFIX = "multilanguageInlineForm";
export const ACTION_MULTILANGUAGE_INLINE_FORM_REQ = `${PREFIX}.ACTION_MULTILANGUAGE_INLINE_FORM_REQ`;
export const ACTION_MULTILANGUAGE_INLINE_FORM_RES = `${PREFIX}.ACTION_MULTILANGUAGE_INLINE_FORM_RES`;
export const ACTION_MULTILANGUAGE_INLINE_FORM_ERR = `${PREFIX}.ACTION_MULTILANGUAGE_INLINE_FORM_ERR`;
export const ACTION_MULTILANGUAGE_INLINE_FORM_DESTROY = `${PREFIX}.ACTION_MULTILANGUAGE_INLINE_FORM_DESTROY`;
export const ACTION_MULTILANGUAGE_INLINE_FORM_UPDATE = `${PREFIX}.ACTION_MULTILANGUAGE_INLINE_FORM_UPDATE`;


function ActionMultilanguageInlineForm() {
  return {
    update,
    destroy,
    post
  };

  function post({ key, locale }) {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION_MULTILANGUAGE_INLINE_FORM_REQ });
      try {
        const {
          multilanguageInlineForm: { formData },
          multilanguageKeys: { collection }
        } = getState();
        const createdItem = await postMultilang({ formData, locale, key });
        const clonedCollection = _.cloneDeep(collection);
        clonedCollection[key] = {};
        clonedCollection[key][locale] = _.get(createdItem, "translation");
        dispatch({
          type: "multilanguageKeys.ACTION_MULTILANGUAGE_KEYS_UPDATE",
          payload: clonedCollection
        });
      } catch (err) {
        dispatch({ type: ACTION_MULTILANGUAGE_INLINE_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_MULTILANGUAGE_INLINE_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_MULTILANGUAGE_INLINE_FORM_DESTROY };
  }
}

export default ActionMultilanguageInlineForm();
