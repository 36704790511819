import {
  ACTION_MULTILANGUAGE_INLINE_FORM_REQ,
  ACTION_MULTILANGUAGE_INLINE_FORM_RES,
  ACTION_MULTILANGUAGE_INLINE_FORM_ERR,
  ACTION_MULTILANGUAGE_INLINE_FORM_UPDATE,
  ACTION_MULTILANGUAGE_INLINE_FORM_DESTROY
} from "../actions/multilanguage-inline-form";

const initialState = {
  formData: {},
  error: false,
  loading: false
};

const multilanguageInlineForm = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_MULTILANGUAGE_INLINE_FORM_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_MULTILANGUAGE_INLINE_FORM_RES:
      return {
        ...state,
        loading: false,
        formData: action.payload
      };
    case ACTION_MULTILANGUAGE_INLINE_FORM_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_MULTILANGUAGE_INLINE_FORM_UPDATE:
      return {
        ...state,
        formData: action.payload,
        loading: false
      };
    case ACTION_MULTILANGUAGE_INLINE_FORM_DESTROY:
      return {
        formData: null,
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default multilanguageInlineForm;
