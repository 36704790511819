import { API_URL } from "../constants";
import axios from "axios";

export default class AuthService {
  async logIn(data) {
    if (!data) {
      return false;
    }
    return axios
      .post(`${API_URL}/login`, data, { withCredentials: true })
      .then(response => response.data);
  }

  async logout() {
    return axios
      .post(`${API_URL}/logout`, {}, { withCredentials: true })
      .then(response => response.data);
  }

  async register(data) {
    if (!data) {
      return false;
    }
    return axios
      .post(`${API_URL}/register`, data, { withCredentials: true })
      .then(response => response.data);
  }

  async getSession() {
    return axios
      .get(`${API_URL}/session`, { withCredentials: true })
      .then(response => response.data);
  }

  async createSession() {
    return axios
      .post(`${API_URL}/session/create`, { withCredentials: true })
      .then(response => response.data);
  }
  async getSessions() {
    return axios
      .post(`${API_URL}/session/fetch`, { withCredentials: true })
      .then(response => response.data);
  }
}
