/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardBody from "components/AdminCard/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
// import Loader from "components/Loader/Loader";
import Button from "components/CustomButtons/Button.jsx";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import ActionLanguageList from "../../actions/language-list";
import ActionMultilanguageForm from "../../actions/multilanguage-form";

import { connect } from "react-redux";

import _ from "lodash";

import "./style.scss";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const MultilanguageCreate = ({
  classes,
  countries,
  // loadingCountries,
  formData,
  update,
  post,
  fetch
}) => {
  useEffect(() => {
    fetch();
  }, []);
  const handleFormSubmit = () => {
    post();
  };

  const handleFormChange = (key, event) => {
    const data = _.cloneDeep(formData);
    data[key] = event.target.value;
    update(data);
  };

  const camelCase = str => {
    if (!str || typeof str !== "string") {
      return "";
    }
    return str
      .replace(/\s(.)/g, a => a.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, b => b.toLowerCase());
  };

  return (
    <ValidatorForm className={classes.form} onSubmit={handleFormSubmit}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            <TranslationTextField
              value="MultilanguagePage.CreateMultilanguageTranslation"
              defaultValue="Create multilanguage translation"
            />
          </h4>
        </CardHeader>
        <CardBody>
          <div className="input-container">
            <TextValidator
              fullWidth={true}
              value={formData.uniqueKey}
              label={
                <TranslationTextField
                  value="MultilanguagePage.TranslationUniqueKey"
                  defaultValue="Translation unique key"
                />
              }
              validators={["required"]}
              errorMessages={["This field is required"]}
              onChange={event => handleFormChange("uniqueKey", event)}
            />
          </div>
          <GridContainer justify="flex-start">
            {countries
              ? _.map(countries, country => (
                  <GridItem key={country.key} xs={12} sm={12} md={12}>
                    <div className="input-container">
                      <div className="flex layout-row multilanguage-create_country">
                        <TextValidator
                          fullWidth={true}
                          value={formData[country.key]}
                          label={
                            <TranslationTextField
                              value={`MultilanguagePage.${camelCase(
                                `${country.key} (${country.name})`
                              )}`}
                              defaultValue={`${country.key} (${country.name})`}
                            />
                          }
                          onChange={event =>
                            handleFormChange(country.key, event)
                          }
                          InputProps={{
                            startAdornment: (
                              <i
                                className={`flag-icon flag-icon-${country.key.toLowerCase()} flag-icon dropdown-icon`}
                              />
                            )
                          }}
                        />
                      </div>
                    </div>
                  </GridItem>
                ))
              : null}
          </GridContainer>
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Button type="submit" color="primary" size="sm">
            Save
          </Button>
        </CardFooter>
      </Card>
    </ValidatorForm>
  );
};

const mapStateToProps = ({ languageList, multilanguageForm }) => {
  return {
    ...multilanguageForm,
    countries: _.get(languageList, "collection"),
    loadingCountries: _.get(languageList, "loading")
  };
};

const mapDispatchToProps = {
  fetch: ActionLanguageList.fetch,
  update: ActionMultilanguageForm.update,
  post: ActionMultilanguageForm.post
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MultilanguageCreate)
);
