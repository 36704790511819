import _ from "lodash";
import {
  fetch as fetchLanguage,
  remove as removeLanguage
} from "../services/translation-language-service";

export const PREFIX = "translationLanguageList";
export const ACTION_TRANSLATION_LANGUAGE_LIST_REQ = `${PREFIX}.ACTION_TRANSLATION_LANGUAGE_LIST_REQ`;
export const ACTION_TRANSLATION_LANGUAGE_LIST_RES = `${PREFIX}.ACTION_TRANSLATION_LANGUAGE_LIST_RES`;
export const ACTION_TRANSLATION_LANGUAGE_LIST_ERR = `${PREFIX}.ACTION_TRANSLATION_LANGUAGE_LIST_ERR`;
export const ACTION_TRANSLATION_LANGUAGE_LIST_DESTROY = `${PREFIX}.ACTION_TRANSLATION_LANGUAGE_LIST_DESTROY`;
export const ACTION_TRANSLATION_LANGUAGE_LIST_UPDATE = `${PREFIX}.ACTION_TRANSLATION_LANGUAGE_LIST_UPDATE`;

function ActionLanguageList() {
  return {
    destroy,
    fetch,
    remove
  };

  function fetch() {
    return async dispatch => {
      dispatch({ type: ACTION_TRANSLATION_LANGUAGE_LIST_REQ });
      try {
        const result = await fetchLanguage();
        const payload = _.map(result, toListEntity);
        dispatch({ type: ACTION_TRANSLATION_LANGUAGE_LIST_RES, payload });
      } catch (err) {
        dispatch({ type: ACTION_TRANSLATION_LANGUAGE_LIST_ERR, payload: err });
      }
    };
  }

  function remove(id) {
    return async dispatch => {
      try {
        await removeLanguage(id);
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION_TRANSLATION_LANGUAGE_LIST_ERR, payload: err });
      }
    };
  }

  function destroy() {
    return { type: ACTION_TRANSLATION_LANGUAGE_LIST_DESTROY };
  }

  function toListEntity(item, index) {
    return {
      ...item,
      nr: index + 1,
      label: item.language,
      value: item.code,
      user: _.get(item, "user.email")
    };
  }
}

export default ActionLanguageList();
