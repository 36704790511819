/* eslint-disable react/prop-types */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
import Date from "components/format/Date";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withTranslationService from "../../components/hoc/with-translation-service";
import Modal from "../../components/Modal/Modal.jsx";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import DatePicker from "components/DatePicker/DatePicker.jsx";
import { ActionModal } from "../../actions/index";
import { connect } from "react-redux";

import _ from "lodash";
import moment from "moment";
import "./style.scss";

class Components extends React.Component {
  state = {
    formData: {
      translationId: "",
      translationDate: moment()
    },
    verifiedData: {},
    errorMessage: "",
    validationError: false,
    open: false
  };

  handleFormChange = name => event => {
    const { formData } = this.state;
    formData[name] = event.target.value;
    this.setState({ formData });
  };

  handleFormSubmit = async event => {
    event.preventDefault();
    const { translationService } = this.props;
    const { formData } = this.state;
    const verifiedData = await translationService.verify(formData);
    this.setState({ verifiedData });
    if (_.isEmpty(verifiedData)) {
      this.setState({
        errorMessage:
          "Please check the date and unique ID No. If the information is correct but you see this message, the translation is not registered in this system.",
        verifiedData,
        open: true
      });
    } else {
      this.setState({
        errorMessage: "",
        verifiedData,
        open: true
      });
    }
  };

  handleDateChange = date => {
    const { formData } = this.state;
    const clonedFormData = _.cloneDeep(formData);
    clonedFormData.translationDate = date;
    this.setState({ formData: clonedFormData });
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleModal = () => {
    const { openModal, open } = this.props;
    openModal(!open);
  };

  render() {
    const { classes, ...rest } = this.props;
    const { formData, verifiedData, errorMessage, open } = this.state;
    return (
      <div className="home-page">
        <Header
          brand="Verify Translation"
          leftLinks={<HeaderLeftLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/stamp.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem className="animated fadeIn" xs={12} sm={12} md={12}>
                <div className={classes.brand}>
                  <h1 className={classes.title}>
                    <TranslationTextField
                      value="HomePage.MainTitle"
                      defaultValue="Verify Translation"
                    />
                  </h1>
                  <h3 className={classes.subtitle}>
                    <span className="home-page_sub-titles">
                      <TranslationTextField
                        value="HomePage.MainSubTitle"
                        defaultValue="Verify the Authenticity of Translation"
                      />
                    </span>
                  </h3>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card className="animated fadeIn">
                  <ValidatorForm
                    className={classes.form}
                    onSubmit={this.handleFormSubmit}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>
                        <TranslationTextField
                          value="HomePage.VerificationFormTitle"
                          defaultValue="Enter a translation ID and date and press verify"
                        />
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <div className="input-container">
                        <TextValidator
                          label={
                            <TranslationTextField
                              value="HomePage.TranslationIdLabel"
                              defaultValue="Translation ID"
                            />
                          }
                          onChange={this.handleFormChange("translationId")}
                          fullWidth={true}
                          name="translationId"
                          type="text"
                          value={formData.translationId}
                          validators={[
                            "required",
                            "minNumber:0",
                            "maxNumber:9999999999",
                            "matchRegexp:^[0-9]*$"
                          ]}
                          errorMessages={[
                            "This field is required",
                            "Invalid value",
                            "Invalid value",
                            "Invalid value"
                          ]}
                        />
                      </div>
                      <div className="input-container">
                        <DatePicker
                          date={formData.translationDate}
                          onChange={this.handleDateChange}
                        />
                      </div>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button type="submit" simple color="primary" size="lg">
                        <TranslationTextField
                          value="HomePage.VerifyButton"
                          defaultValue="Verify"
                        />
                      </Button>
                    </CardFooter>
                  </ValidatorForm>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Footer />
        <Modal onClose={this.handleModal} open={open}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {!_.isEmpty(verifiedData) ? (
                <Card className="home-page_modal-card animated fadeIn">
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>
                      <TranslationTextField
                        value="HomePage.Modal.SuccessfulVerificationMessage"
                        defaultValue="Translation successfuly verified"
                      />
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.TranslationId"
                              defaultValue="Translation ID"
                            />
                          </th>
                          <td>{verifiedData.translationId}</td>
                        </tr>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.Translator"
                              defaultValue="Translator"
                            />
                          </th>
                          <td>{verifiedData.translationAgency}</td>
                        </tr>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.TranslationDate"
                              defaultValue="Translation date:"
                            />
                          </th>
                          <td>{<Date value={formData.translationDate} />}</td>
                        </tr>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.DocumenType"
                              defaultValue="Document type:"
                            />
                          </th>
                          <td>
                            <TranslationTextField
                              value={verifiedData.documentType}
                              defaultValue={verifiedData.documentType}
                              hideMinus
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.DocumentNumber"
                              defaultValue="Document number:"
                            />
                          </th>
                          <td>{verifiedData.documentNumber}</td>
                        </tr>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.TranslatedFrom"
                              defaultValue="Translated from:"
                            />
                          </th>
                          <td className="languageIndicatior">
                            <TranslationTextField
                              value={verifiedData.translatedFrom}
                              defaultValue={verifiedData.translatedFrom}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <TranslationTextField
                              value="HomePage.Modal.TranslatedTo"
                              defaultValue="Translated to:"
                            />
                          </th>
                          <td className="languageIndicatior">
                            <TranslationTextField
                              value={verifiedData.translatedTo}
                              defaultValue={verifiedData.translatedTo}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="primary" onClick={this.handleClose}>
                      Close
                    </Button>
                  </CardFooter>
                </Card>
              ) : null}
              {errorMessage ? (
                <Card className="home-page_modal-card animated fadeIn">
                  <ValidatorForm
                    className={classes.form}
                    onSubmit={this.handleFormSubmit}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>
                        <TranslationTextField
                          value="HomePage.Modal.VerificationFaledMessage"
                          defaultValue="Unfortunately, we were unable to verify this
                          translation."
                        />
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <TranslationTextField
                        value="HomePage.UnsuccessfulVerificationMessage"
                        defaultValue={errorMessage}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button color="primary" onClick={this.handleClose}>
                        <TranslationTextField
                          value="HomePage.Modal.TryAgain"
                          defaultValue="Try again"
                        />
                      </Button>
                    </CardFooter>
                  </ValidatorForm>
                </Card>
              ) : null}
            </GridItem>
          </GridContainer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ modal }) => {
  return { open: modal.open };
};

const mapDispatchToProps = { openModal: ActionModal.update };

export default withTranslationService()(
  withStyles(componentsStyle)(
    connect(mapStateToProps, mapDispatchToProps)(Components)
  )
);
