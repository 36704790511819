/* eslint-disable react/prop-types */
import React, { useState } from "react";

import TranslationForm from "containers/translation-form/TranslationForm";
import TranslationLanguageForm from "containers/translation-language-form/TranslationLanguageForm";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import FiberNewIcon from "@material-ui/icons/FiberNew";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
import TranslationLanguageList from "containers/translation-language-list/TranslationLanguageList";

import "./style.scss";

export default function Translation() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return value === index && <Box p={1}>{children}</Box>;
  }
  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label={
              <TranslationTextField
                value="TranslationPage.CreateTranslationsTab"
                defaultValue="Create translation"
              />
            }
            icon={<GTranslateIcon />}
          />
          <Tab
            label={
              <TranslationTextField
                value="TranslationPage.CreateTab"
                defaultValue="Create language"
              />
            }
            icon={<FiberNewIcon />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TranslationForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TranslationLanguageForm />
        <TranslationLanguageList />
      </TabPanel>
    </>
  );
}
