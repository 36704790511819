import { fetch as fetchMultilanguage } from "../services/multilanguage-service";
import _ from "lodash";

export const PREFIX = "multilanguageKeys";
export const ACTION_MULTILANGUAGE_KEYS_DESTROY = `${PREFIX}.ACTION_MULTILANGUAGE_KEYS_DESTROY`;
export const ACTION_MULTILANGUAGE_KEYS_REQ = `${PREFIX}.ACTION_MULTILANGUAGE_KEYS_REQ`;
export const ACTION_MULTILANGUAGE_KEYS_ERR = `${PREFIX}.ACTION_MULTILANGUAGE_KEYS_ERR`;
export const ACTION_MULTILANGUAGE_KEYS_RES = `${PREFIX}.ACTION_MULTILANGUAGE_KEYS_RES`;
export const ACTION_MULTILANGUAGE_KEYS_UPDATE = `${PREFIX}.ACTION_MULTILANGUAGE_KEYS_UPDATE`;

function ActionMultilanguageKeys() {
  return {
    fetch,
    update
  };

  function fetch() {
    return async dispatch => {
      dispatch({ type: ACTION_MULTILANGUAGE_KEYS_REQ });
      try {
        const result = await fetchMultilanguage();
        const entity = _.map(result, toListEntity);
        const groupedByKey = _.groupBy(entity, "key");
        const groupedByLocale = _.groupBy(entity, "locale");
        const payload = {};
        _.forEach(groupedByKey, (item, key) => {
          payload[key] = {};
          _.forEach(groupedByLocale, (item, locale) => {
            payload[key][locale] = _.get(_.find(item, { key }), "translation");
          });
        });
        dispatch({ type: ACTION_MULTILANGUAGE_KEYS_RES, payload });
      } catch (err) {
        return dispatch({ type: ACTION_MULTILANGUAGE_KEYS_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_MULTILANGUAGE_KEYS_UPDATE,
      payload
    };
  }

  function toListEntity(item, index) {
    return {
      ...item,
      nr: index + 1,
      flag: `flag-icon flag-icon-${item.locale.toLowerCase()}`
    };
  }
}

export default ActionMultilanguageKeys();
