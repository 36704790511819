import { ACTION_LOGOUT_REQ, ACTION_LOGOUT_ERR } from "../actions/auth";

export default authLogout;

const initialState = {
  loading: false,
  error: false
};

function authLogout(state = initialState, action) {
  switch (action.type) {
    case ACTION_LOGOUT_REQ:
      return {
        ...state,
        logoutLoading: true,
        logoutError: false
      };
    case ACTION_LOGOUT_ERR:
      return {
        ...state,
        logoutLoading: false,
        logoutError: true
      };
    default:
      return state;
  }
}
