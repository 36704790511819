import { post as postLanguage } from "../services/translation-language-service";
import _ from "lodash";

export const PREFIX = "translationLanguageForm";
export const ACTION__TRANSLATION_LANGUAGE_FORM_REQ = `${PREFIX}.ACTION__TRANSLATION_LANGUAGE_FORM_REQ`;
export const ACTION__TRANSLATION_LANGUAGE_FORM_RES = `${PREFIX}.ACTION__TRANSLATION_LANGUAGE_FORM_RES`;
export const ACTION__TRANSLATION_LANGUAGE_FORM_ERR = `${PREFIX}.ACTION__TRANSLATION_LANGUAGE_FORM_ERR`;
export const ACTION__TRANSLATION_LANGUAGE_FORM_DESTROY = `${PREFIX}.ACTION__TRANSLATION_LANGUAGE_FORM_DESTROY`;
export const ACTION__TRANSLATION_LANGUAGE_FORM_UPDATE = `${PREFIX}.ACTION__TRANSLATION_LANGUAGE_FORM_UPDATE`;

function ActionTranslationLanguageForm() {
  return {
    update,
    destroy,
    post
  };

  function post() {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION__TRANSLATION_LANGUAGE_FORM_REQ });
      try {
        const {
          translationLanguageForm: { formData }
        } = getState();
        await postLanguage(toPostEntity(formData));
        dispatch({ type: ACTION__TRANSLATION_LANGUAGE_FORM_DESTROY });
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION__TRANSLATION_LANGUAGE_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION__TRANSLATION_LANGUAGE_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION__TRANSLATION_LANGUAGE_FORM_DESTROY };
  }

  function toPostEntity(item) {
    return {
      ...item,
      code: _.toUpper(item.code)
    };
  }
}

export default ActionTranslationLanguageForm();
