import _ from "lodash";
import TranslationNotification from "containers/translation-notification/TranslationNotification";
import TranslationService from "../services/translation-service";
const translationService = new TranslationService();

export const PREFIX = "translationsList";
export const ACTION_TRANSLATIONS_LIST_REQ = `${PREFIX}.ACTION_TRANSLATIONS_LIST_REQ`;
export const ACTION_TRANSLATIONS_LIST_RES = `${PREFIX}.ACTION_TRANSLATIONS_LIST_RES`;
export const ACTION_TRANSLATIONS_LIST_ERR = `${PREFIX}.ACTION_TRANSLATIONS_LIST_ERR`;
export const ACTION_TRANSLATIONS_LIST_DESTROY = `${PREFIX}.ACTION_TRANSLATIONS_LIST_DESTROY`;
export const ACTION_TRANSLATIONS_LIST_UPDATE = `${PREFIX}.ACTION_TRANSLATIONS_LIST_UPDATE`;

function ActionTranslationsFetch() {
  return {
    fetch,
    fetchByUser,
    showMessage,
    remove
  };

  function remove(id) {
    if (!id) {
      return;
    }
    return async dispatch => {
      try {
        await translationService.remove({ translationId: id });
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION_TRANSLATIONS_LIST_ERR, payload: err });
      }
    };
  }

  function fetch() {
    return async dispatch => {
      dispatch({ type: ACTION_TRANSLATIONS_LIST_REQ });
      try {
        const data = await translationService.fetch();
        const payload = _.orderBy(data, ["translationId"], ["desc"]);
        dispatch({
          type: ACTION_TRANSLATIONS_LIST_RES,
          payload: _.map(payload, toListEntity)
        });
      } catch (err) {
        dispatch({ type: ACTION_TRANSLATIONS_LIST_ERR, payload: err });
      }
    };
  }

  function fetchByUser(userId) {
    return async dispatch => {
      dispatch({ type: ACTION_TRANSLATIONS_LIST_REQ });
      try {
        const data = await translationService.fetchByUser(userId);
        const payload = _.orderBy(data, ["translationId"], ["desc"]);
        dispatch({
          type: ACTION_TRANSLATIONS_LIST_RES,
          payload: _.map(payload, toListEntity)
        });
      } catch (err) {
        dispatch({ type: ACTION_TRANSLATIONS_LIST_ERR, payload: err });
      }
    };
  }

  function showMessage(translation) {
    if (!translation) {
      return;
    }
    return async (dispatch, getState) => {
      try {
        const { notifications } = getState();
        const messages = notifications.messages;
        const message = {
          isCopied: false,
          isClosed: false,
          component: TranslationNotification,
          data: {
            translationId: translation.translationId,
            translationDate: translation.translationDate,
            translatedTo: translation.translatedTo
          }
        };

        if (message) {
          const newMessages = [...messages, message];
          dispatch({
            type: "notifications.ACTION_NOTIFICATIONS_UPDATE",
            payload: newMessages
          });
        }
      } catch (err) {
        dispatch({
          type: "notifications.ACTION_NOTIFICATIONS_ERR",
          payload: err
        });
      }
    };
  }

  function toListEntity(item, index) {
    return {
      nr: index + 1,
      id: item.id,
      translationId: item.translationId || "-",
      translationDate: item.translationDate,
      translatedFrom: item.translatedFrom || "-",
      translatedTo: item.translatedTo || "-",
      documentType: item.documentType || "-",
      documentNumber: item.documentNumber || "-",
      user: _.get(item.user, "email") || "-"
    };
  }
}

export default ActionTranslationsFetch();
