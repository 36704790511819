/* eslint-disable react/prop-types */
import React, { useState } from "react";

import LanguageList from "../../containers/language-list/LanguageList";
import GridItem from "components/AdminGrid/GridItem";
import GridContainer from "components/AdminGrid/GridContainer";
import Button from "components/AdminCustomButtons/Button";
import LanguageCreate from "../../containers/language-create/LanguageCreate";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

function LanguagePage() {
  const [isActive, setIsActive] = useState(false);

  const handleFormActive = () => {
    setIsActive(!isActive);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className="flex layout-row">
          <Button
            type="button"
            color="primary"
            onClick={handleFormActive}
            style={{ marginRight: 10, height: "3rem" }}
          >
            <TranslationTextField
              value="LanguagePage.NewLanguage"
              defaultValue="New language"
            />
          </Button>
          {isActive ? <LanguageCreate /> : null}
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <LanguageList />
      </GridItem>
    </GridContainer>
  );
}

export default LanguagePage;
