const columns = [
  {
    id: "nr",
    name: "Nr",
    type: "string",
    isHidden: true,
    size: 60
  },
  {
    id: "key",
    name: "Code",
    type: "string"
  },
  {
    id: "name",
    name: "Country",
    type: "string"
  },
  {
    id: "flag",
    name: "Flag",
    type: "icon"
  },
  {
    id: "remove",
    name: "Remove",
    type: "icon",
    event: "deleteItem",
    icon: "fa fa-trash"
  }
];

export default columns;
