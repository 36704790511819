/* eslint-disable react/prop-types */
import React, { useState } from "react";

import GTranslateIcon from "@material-ui/icons/GTranslate";
import FiberNewIcon from "@material-ui/icons/FiberNew";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import MultilanguageList from "containers/multilanguage-list/MultilanguageList";
import MultilanguageCreate from "containers/multilanguage-create/MultilanguageCreate";
import Box from "@material-ui/core/Box";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

const MultilanguagePage = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return value === index && <Box p={1}>{children}</Box>;
  }

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label={
              <TranslationTextField
                value="MultilanguagePage.TranslationsTab"
                defaultValue="Translations"
              />
            }
            icon={<GTranslateIcon />}
          />
          <Tab
            label={
              <TranslationTextField
                value="MultilanguagePage.CreateTab"
                defaultValue="Create"
              />
            }
            icon={<FiberNewIcon />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MultilanguageList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MultilanguageCreate />
      </TabPanel>
    </div>
  );
};

export default MultilanguagePage;
