import {
  fetchUserStatystics,
  fetchVerificationStatystics
} from "../services/statystics";
import { fetch as fetchFeedback } from "../services/feedback";
import moment from "moment";
import _ from "lodash";

export const PREFIX = "userStatystics";
export const ACTION_USER_STATYSTICS_REQ = `${PREFIX}.ACTION_USER_STATYSTICS_REQ`;
export const ACTION_USER_STATYSTICS_RES = `${PREFIX}.ACTION_USER_STATYSTICS_RES`;
export const ACTION_USER_STATYSTICS_ERR = `${PREFIX}.ACTION_USER_STATYSTICS_ERR`;
export const ACTION_USER_STATYSTICS_DESTROY = `${PREFIX}.ACTION_USER_STATYSTICS_DESTROY`;
export const ACTION_USER_STATYSTICS_UPDATE = `${PREFIX}.ACTION_USER_STATYSTICS_UPDATE`;

function ActionUserStatystics() {
  return {
    fetch
  };

  function fetch() {
    return async dispatch => {
      dispatch({ type: ACTION_USER_STATYSTICS_REQ });
      try {
        const todayDate = moment().format("L");
        const [userStatystics, verifyStatystics, feedback] = await Promise.all([
          fetchUserStatystics(),
          fetchVerificationStatystics(),
          fetchFeedback()
        ]);
        const mappedFeedback = _.map(feedback, toFeedbackEntity);
        const mappedCollection = _.map(userStatystics, toEntity);
        const mappedVerifyStatystics = _.map(
          verifyStatystics,
          toVerifyStatysticsEntity
        );
        const todayVerifications = _.filter(
          mappedVerifyStatystics,
          item => item.date === todayDate
        );
        const allVisits = _.filter(mappedCollection, item => !item.user);
        const todayVisits = _.filter(
          mappedCollection,
          item => !item.user && item.date === todayDate
        );
        const allSessions = _.filter(mappedCollection, item => item.user);
        const todaySessions = _.filter(
          mappedCollection,
          item => item.user && item.date === todayDate
        );
        const lastSession = _.maxBy(allSessions, "datetime");
        const lastVisit = _.maxBy(allVisits, "datetime");
        const lastVerification = _.maxBy(mappedVerifyStatystics, "datetime");
        const data = {
          allVisits,
          todayVisits,
          allSessions,
          todaySessions,
          lastSession,
          lastVisit,
          allVerifications: mappedVerifyStatystics,
          todayVerifications,
          lastVerification,
          allSessionsAndVisits: mappedCollection,
          feedback: mappedFeedback
        };
        dispatch({
          type: ACTION_USER_STATYSTICS_RES,
          payload: data
        });
      } catch (err) {
        dispatch({ type: ACTION_USER_STATYSTICS_ERR, payload: err });
      }
    };
  }
}

function toEntity(item, index) {
  return {
    ...item,
    nr: index + 1,
    user: _.get(item.user, "email") || null,
    datetime: moment(item.datetime)
      .local()
      .format("LLL"),
    date: moment(item.date)
      .local()
      .format("L")
  };
}

function toVerifyStatysticsEntity(item, index) {
  return {
    ...item,
    nr: index + 1,
    ip: _.get(item, "session.ip"),
    locale: _.get(item, "session.locale"),
    user: _.get(item, "user.email") || null,
    translationId: _.get(item, "translation.translationId"),
    datetime: moment(item.datetime)
      .local()
      .format("LLL"),
    date: moment(item.date)
      .local()
      .format("L")
  };
}

function toFeedbackEntity(item, index) {
  return {
    ...item,
    nr: index + 1,
    datetime: moment(item.datetime)
      .local()
      .format("LLL"),
    date: moment(item.date)
      .local()
      .format("L"),
    ip: _.get(item, "session.ip"),
    locale: _.get(item, "session.locale"),
    user: _.get(item, "user.email") || null
  };
}

export default ActionUserStatystics();
