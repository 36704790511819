/* eslint-disable react/prop-types */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import Modal from "../../components/Modal/Modal.jsx";

import { connect } from "react-redux";
import ActionAuthSession from "../../actions/auth";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import "./style.scss";

import Translation from "views/Translation/Translation.jsx";

import { ActionModal, ActionAuth } from "../../actions/index";

import _ from "lodash";

function HeaderLinks({
  logOut,
  openModal,
  open,
  modalId,
  classes,
  user,
  switchTranslateUiMode
}) {
  const handleLogOut = () => {
    logOut();
  };

  const handleTranslationModal = () => {
    openModal({ open: !open, modalId: "translationForm" });
  };

  const handleEditTranslationMode = () => {
    switchTranslateUiMode();
    if (localStorage.getItem("translateUiMode")) {
      localStorage.removeItem("translateUiMode");
    } else {
      localStorage.setItem("translateUiMode", "true");
    }
  };

  return (
    <div>
      <List className="admin-navbar-links">
        {_.get(user, "role") === "admin" ||
        _.get(user, "role") === "superadmin" ? (
          <ListItem className={classes.listItem}>
            <div
              onClick={handleEditTranslationMode}
              className="admin-navbar-links_link"
            >
              <TranslationTextField
                value="AdminNavbarLink.TranslateUI"
                defaultValue="Translate UI"
              />
            </div>
          </ListItem>
        ) : null}
        <ListItem className={classes.listItem}>
          <div
            onClick={handleTranslationModal}
            className="admin-navbar-links_link"
          >
            <TranslationTextField
              value="AdminNavbarLink.NewTranslation"
              defaultValue="New translation"
            />
          </div>
        </ListItem>
        <ListItem className={classes.listItem}>
          <div onClick={handleLogOut} className="admin-navbar-links_link">
            <TranslationTextField
              value="AdminNavbarLink.LogOut"
              defaultValue="Log out"
            />
          </div>
        </ListItem>
      </List>
      <Modal
        onClose={handleTranslationModal}
        open={open && modalId === "translationForm"}
      >
        <Translation />
      </Modal>
    </div>
  );
}

const mapStateToProps = ({ authSession, modal }) => {
  return {
    ...authSession,
    open: modal.open,
    modalId: modal.modalId,
    user: _.get(authSession, "session.user")
  };
};

const mapDispatchToProps = {
  update: ActionAuthSession.update,
  logOut: ActionAuthSession.logout,
  openModal: ActionModal.update,
  switchTranslateUiMode: ActionAuth.setTranslateUiMode
};

export default withStyles(headerLinksStyle)(
  connect(mapStateToProps, mapDispatchToProps)(HeaderLinks)
);
