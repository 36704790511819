export const PREFIX = "modal";
export const ACTION_MODAL_DESTROY = `${PREFIX}.ACTION_MODAL_DESTROY`;
export const ACTION_MODAL_UPDATE = `${PREFIX}.ACTION_MODAL_UPDATE`;

function ActionModal() {
  return {
    update,
    destroy
  };

  function update(payload) {
    const { open, modalId } = payload;
    return {
      type: ACTION_MODAL_UPDATE,
      payload: {
        modalId,
        open
      }
    };
  }

  function destroy() {
    return { type: ACTION_MODAL_DESTROY };
  }
}

export default ActionModal();
