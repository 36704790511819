import { ACTION_MODAL_UPDATE, ACTION_MODAL_DESTROY } from "../actions/modal";

const initialState = {
  open: false,
  modalId: null,
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_MODAL_UPDATE:
      return {
        ...state,
        open: action.payload.open,
        modalId: action.payload.modalId,
      };
    case ACTION_MODAL_DESTROY:
      return initialState;
    default:
      return state;
  }
};

export default modal;
