import { ACTION_LOGIN_REQ, ACTION_LOGIN_ERR } from "../actions/auth";

export default authLogin;

const initialState = {
  loading: false,
  error: false
};

function authLogin(state = initialState, action) {
  switch (action.type) {
    case ACTION_LOGIN_REQ:
      return {
        ...state,
        loading: true,
        error: false,
        user: {}
      };
    case ACTION_LOGIN_ERR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
