/*eslint-disable*/
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import { connect } from "react-redux";
import ActionAuthSession from "../../actions/auth";

class HeaderLinks extends PureComponent {
  getLinks() {
    const { user, classes } = this.props;
    if (user) {
      return (
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Link to={"/"} className={classes.navLink}>
              <TranslationTextField
                value="HeaderLinks.Home"
                defaultValue="Home"
              />
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to={"/about"} className={classes.navLink}>
              <TranslationTextField
                value="HeaderLinks.AboutUs"
                defaultValue="About Us"
              />
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to={"/admin"} className={classes.navLink}>
              <TranslationTextField
                value="HeaderLinks.Dashboard"
                defaultValue="Dashboard"
              />
            </Link>
          </ListItem>
        </List>
      );
    }
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link to={"/"} className={classes.navLink}>
            <TranslationTextField
              value="HeaderLinks.Home"
              defaultValue="Home"
            />
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to={"/about"} className={classes.navLink}>
            <TranslationTextField
              value="HeaderLinks.AboutUs"
              defaultValue="About Us"
            />
          </Link>
        </ListItem>
      </List>
    );
  }

  render() {
    return (this.getLinks());
  }
}

const mapStateToProps = ({ authSession }) => {
  return {
    session: authSession.session,
    user: _.get(authSession.session, "user")
  };
};

const mapDispatchToProps = {
  update: ActionAuthSession.update
};

export default withStyles(headerLinksStyle)(
  connect(mapStateToProps, mapDispatchToProps)
    (HeaderLinks)
);
