/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import Globe from "./spinningGlobe.gif";
import SaveIcon from "@material-ui/icons/Save";
import {
  ActionModal,
  ActionLanguageList,
  ActionMultilanguageForm,
} from "../../actions/index";
import { connect } from "react-redux";

import _ from "lodash";

import "./style.scss";

function TranslatedTextField({
  value,
  multilanguageKeys,
  locale = "GB",
  user,
  defaultValue,
  post,
  formData,
  update,
  openModal,
  open,
  hideMinus,
  translateUiMode
}) {
  const [isEdit, setIsEdit] = useState(false);

  const handleMultiEditTranslationModal = () => {
    update({ uniqueKey: value, ...multilanguageKeys[value] });
    openModal({ open: !open, modalId: "translationsEditForm" });
  }

  const disableEvents = (e, isGlobe) => {
    if (isGlobe) {
      e.stopPropagation();
      e.preventDefault();
      handleMultiEditTranslationModal();
    } else {
      e.returnValue = true;
    }
  };

  const condition =
    (_.isEmpty(multilanguageKeys) ||
      !value ||
      !multilanguageKeys[value] ||
      !multilanguageKeys[value][locale]);

  const getValue = () => {
    if (isEdit) {
      return getTextInput();
    }
    if (!condition && !isEdit) {
      return multilanguageKeys[value][locale];
    }
    return `${defaultValue}${user && (user.role === "admin" || user.role === "superadmin") && !hideMinus ? ' -' : ''}`;
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    post({ key: value, locale });
    setIsEdit(false);
  };
  const handleFormChange = event => {
    const data = _.cloneDeep(formData);
    data[value] = {};
    data[value][locale] = event.target.value;
    update(data);
  };

  const getTextInput = () => {
    return (
      <span className="translated-text-field_form">
        <input
          className="translated-text-field_form-input"
          placeholder={value}
          onChange={handleFormChange}
          value={
            _.get(formData[value], `${locale}`) ||
              _.get(formData[value], `${locale}`) === ""
              ? _.get(formData[value], `${locale}`)
              : _.get(multilanguageKeys[value], `${locale}`) || defaultValue
          }
        />
        <IconButton
          color="primary"
          aria-label="Save"
          type="submit"
          className="translated-text-field_form-sbm-btn"
          onClick={handleFormSubmit}
        >
          <SaveIcon className="translated-text-field_form-sbm-icon" />
        </IconButton>
      </span>
    );
  };

  return (
    <span
      className="translated-text-field"
    >
      {getValue()}
      {user && (user.role === "admin" || user.role === "superadmin") && translateUiMode ? (
        <span onClick={disableEvents} title={value} className="translated-text-field_globe">
          <img
            title={value}
            onClick={(e) => disableEvents(e, true)}
            src={Globe}
            className="translated-text-field_globe-img"
            alt="globe"
          />
        </span>
      ) : null}
    </span>
  );
}

const mapStateToProps = ({
  multilanguageKeys,
  authSession,
  multilanguageInlineForm,
  modal
}) => {
  return {
    ...authSession,
    formData: _.get(multilanguageInlineForm, "formData"),
    multilanguageKeys: _.get(multilanguageKeys, "collection"),
    locale: _.get(authSession, "locale"),
    user: _.get(authSession, "session.user"),
    open: modal.open,
    modalId: modal.modalId,
    translateUiMode: _.get(authSession, 'translateUiMode')
  };
};

const mapDispatchToProps = {
  fetch: ActionLanguageList.fetch,
  remove: ActionLanguageList.remove,
  destroy: ActionLanguageList.destroy,
  update: ActionMultilanguageForm.update,
  post: ActionMultilanguageForm.post,
  openModal: ActionModal.update
};

TranslatedTextField.defaultProps = {
  value: "Default key",
  defaultValue: "Default value",
  hideMinus: false,
};

TranslatedTextField.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  hideMinus: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatedTextField);
