const columns = [
  {
    id: "nr",
    name: "Nr",
    type: "string",
    size: 60
  },
  {
    id: "datetime",
    name: "Datetime",
    type: "datetime",
    size: 60
  },
  {
    id: "ip",
    name: "IP",
    type: "string",
    size: 60
  },
  {
    id: "locale",
    name: "Locale",
    type: "string"
  },
  {
    id: "translationId",
    name: "Translation ID",
    type: "string"
  },
  {
    id: "user",
    name: "User",
    type: "string"
  }
];

export default columns;
