import moment from "moment";

import {
  ACTION_TRANSLATION_FORM_REQ,
  ACTION_TRANSLATION_FORM_RES,
  ACTION_TRANSLATION_FORM_ERR,
  ACTION_TRANSLATION_FORM_UPDATE,
  ACTION_TRANSLATION_FORM_DESTROY
} from "../actions/translation-form";

const initialState = {
  formData: {
    translationDate: moment(),
    translationAgency: "",
    documentType: "",
    documentNumber: "",
    translatedFrom: "",
    translatedTo: ""
  },
  error: false,
  loading: false
};

const translationForm = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TRANSLATION_FORM_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_TRANSLATION_FORM_RES:
      return {
        ...state,
        loading: false,
        formData: action.payload
      };
    case ACTION_TRANSLATION_FORM_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_TRANSLATION_FORM_UPDATE:
      return {
        ...state,
        formData: action.payload,
        loading: false
      };
    case ACTION_TRANSLATION_FORM_DESTROY:
      return {
        formData: {
          translationDate: moment(),
          translationAgency: "",
          documentType: "",
          documentNumber: "",
          translatedFrom: "en",
          translatedTo: "lt"
        },
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default translationForm;
