import React from "react";
import PropTypes from "prop-types";

import Button from "components/AdminCustomButtons/Button.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import _ from "lodash";

function DocumentTypeForm({ onSubmit, formData, isEdit, onChange }) {
  const handleFormSubmit = () => {
    onSubmit();
  };
  const handleChange = name => e => {
    const data = _.cloneDeep(formData);
    data[name] = e.target.value;
    onChange(data);
  };

  const getSumbitButton = () => {
    if (isEdit) {
      return (
        <TranslationTextField value="Button.Update" defaultValue="Update" />
      );
    }
    return <TranslationTextField value="Button.Create" defaultValue="Create" />;
  };

  return (
    <ValidatorForm
      style={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        margin: 0
      }}
      onSubmit={handleFormSubmit}
    >
      <div className="flex layout-row">
        <Button
          type="submit"
          color="primary"
          style={{ marginRight: 10, height: "3rem" }}
        >
          {getSumbitButton()}
        </Button>
        <TextValidator
          label={
            <TranslationTextField
              value="DocumentTypeForm.Name"
              defaultValue="Document type"
            />
          }
          fullWidth={true}
          style={{ width: "20rem" }}
          validators={["required"]}
          errorMessages={["Field is required"]}
          onChange={handleChange("label")}
          value={formData.label}
        />
      </div>
    </ValidatorForm>
  );
}

DocumentTypeForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool
};

DocumentTypeForm.dafaultProps = {
  formData: {},
  onChange: () => false,
  onSubmit: () => false,
  isEdit: false
};

export default DocumentTypeForm;
