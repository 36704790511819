import { post as postLanguage } from "../services/language-service";

export const PREFIX = "languageForm";
export const ACTION_LANGUAGE_FORM_REQ = `${PREFIX}.ACTION_LANGUAGE_FORM_REQ`;
export const ACTION_LANGUAGE_FORM_RES = `${PREFIX}.ACTION_LANGUAGE_FORM_RES`;
export const ACTION_LANGUAGE_FORM_ERR = `${PREFIX}.ACTION_LANGUAGE_FORM_ERR`;
export const ACTION_LANGUAGE_FORM_DESTROY = `${PREFIX}.ACTION_LANGUAGE_FORM_DESTROY`;
export const ACTION_LANGUAGE_FORM_UPDATE = `${PREFIX}.ACTION_LANGUAGE_FORM_UPDATE`;

function ActionLanguageForm() {
  return {
    update,
    destroy,
    post
  };

  function post() {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION_LANGUAGE_FORM_REQ });
      try {
        const {
          languageForm: { collection }
        } = getState();
        await postLanguage(collection);
        dispatch({ type: ACTION_LANGUAGE_FORM_DESTROY });
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION_LANGUAGE_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_LANGUAGE_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_LANGUAGE_FORM_DESTROY };
  }
}

export default ActionLanguageForm();
