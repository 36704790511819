import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

class AboutUsPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Verify Translation"
          leftLinks={<HeaderLeftLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/stamp.jpg")}>
          <div className={classes.container}>
            <div className="animated fadeIn">
              <div className={classes.brand}>
                <h1 className={classes.title}>
                  <TranslationTextField
                    value="AboutUs.MainTitle"
                    defaultValue="About Us"
                  />
                </h1>
                <h3 className={classes.subtitle}>
                  <TranslationTextField
                    value="AboutUsPage.Description"
                    defaultValue="www.verifytranslation.com is the system which is designed for
                    registering translations by translation bureaus and individual
                    translators, and authenticity verification of translation by
                    the final users."
                  />
                </h3>
                <h3 className={classes.subtitle}>
                  <TranslationTextField
                    value="AboutUsPage.SubDescription"
                    defaultValue="Be sure that your translation was done by professionals!"
                  />
                </h3>
              </div>
            </div>
          </div>
        </Parallax>
        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(AboutUsPage);
