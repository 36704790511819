/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/AdminGrid/GridItem.jsx";
import GridContainer from "components/AdminGrid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardBody from "components/AdminCard/CardBody.jsx";

import ActionTranslations from "../../actions/translations-list";

import { connect } from "react-redux";
import _ from "lodash";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const header = [
  {
    id: "nr",
    name: "Nr",
    type: "counter"
  },
  {
    id: "translationId",
    name: "Translation ID",
    type: "string"
  },
  {
    id: "translationDate",
    name: "Date",
    type: "date"
  },
  {
    id: "translatedFrom",
    name: "From",
    type: "string",
    class: "languageIndicatior",
    translatable: true
  },
  {
    id: "translatedTo",
    name: "To",
    type: "string",
    class: "languageIndicatior",
    translatable: true
  },
  {
    id: "documentType",
    name: "Document type",
    type: "string",
    translatable: true
  },
  {
    id: "documentNumber",
    name: "Document number",
    type: "string"
  },
  {
    id: "user",
    name: "User",
    type: "string",
    adminOnly: true
  },
  {
    id: "showMessage",
    name: "Show",
    type: "icon",
    event: "showMessage",
    icon: "fa fa-eye"
  },
  // {
  //   id: "edit",
  //   name: "Edit",
  //   type: "icon",
  //   event: "editItem",
  //   icon: "fa fa-pencil",
  //   align: "center"
  // },
  {
    id: "remove",
    name: "Remove",
    type: "icon",
    event: "deleteItem",
    icon: "fa fa-trash"
  }
];

class TranslationsByUser extends Component {
  async componentDidMount() {
    const { fetchByUser, userId } = this.props;
    fetchByUser(userId);
  }

  handleTableEvent = (eventId, item) => {
    const { collection, showMessage, remove } = this.props;
    const data = _.find(collection, { id: item });
    switch (eventId) {
      case "showMessage":
        showMessage(data);
        break;
      case "deleteItem":
        remove(item);
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, collection, isLoading, user } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>My translations</h4>
            </CardHeader>
            <CardBody>
              <Table
                isLoading={isLoading}
                tableHeaderColor="primary"
                tableHead={header}
                tableData={collection}
                onTableEvent={this.handleTableEvent}
                user={user}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ translationsList, authSession }) => {
  return {
    isLoading: translationsList.loading,
    isError: translationsList.error,
    collection: translationsList.collection,
    user: _.get(authSession, "session.user"),
    userId: _.get(authSession.session, "user")
      ? _.get(authSession.session, "user").id
      : null
  };
};

const mapDispatchToProps = {
  fetchByUser: ActionTranslations.fetchByUser,
  showMessage: ActionTranslations.showMessage
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TranslationsByUser)
);
