import React, { PureComponent } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class Loader extends PureComponent {
  render() {
    return (
      <div className="flex layout-column justify-center align-center">
        <CircularProgress disableShrink color="primary" size={60} />
      </div>
    );
  }
}
