import {
  ACTION_NOTIFICATIONS_UPDATE,
  ACTION_NOTIFICATIONS_DESTROY,
  ACTION_NOTIFICATIONS_ERR,
} from "../actions/notifications";

const initialState = {
  messages: [],
  error: false,
  loading: false,
  errorMessage: null
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_NOTIFICATIONS_UPDATE:
      return {
        ...state,
        messages: action.payload
      };
    case ACTION_NOTIFICATIONS_ERR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload
      };
    case ACTION_NOTIFICATIONS_DESTROY:
      return initialState;
    default:
      return state;
  }
};

export default notifications;
