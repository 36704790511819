import {
  ACTION_MULTILANGUAGE_KEYS_REQ,
  ACTION_MULTILANGUAGE_KEYS_RES,
  ACTION_MULTILANGUAGE_KEYS_ERR,
  ACTION_MULTILANGUAGE_KEYS_UPDATE,
  ACTION_MULTILANGUAGE_KEYS_DESTROY
} from "../actions/multilanguage-keys";

const initialState = {
  collection: null,
  error: false,
  loading: false
};

const multilanguageKeys = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_MULTILANGUAGE_KEYS_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_MULTILANGUAGE_KEYS_RES:
      return {
        ...state,
        loading: false,
        collection: action.payload
      };
    case ACTION_MULTILANGUAGE_KEYS_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_MULTILANGUAGE_KEYS_UPDATE:
      return {
        ...state,
        collection: action.payload
      };
    case ACTION_MULTILANGUAGE_KEYS_DESTROY:
      return {
        collection: null,
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default multilanguageKeys;
