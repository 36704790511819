import { socketService } from "./socket";
import { API_URL } from "../constants";
import axios from "axios";

export async function fetch() {
  return axios
    .post(`${API_URL}/document-type/fetch`, null, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function fetchById(id) {
  if (!id) {
    return false;
  }
  return axios
    .post(
      `${API_URL}/document-type/fetch-by-id`,
      { id },
      { withCredentials: true }
    )
    .then(response => response.data);
}

export async function post(data) {
  if (!data) {
    return false;
  }
  return axios
    .post(`${API_URL}/document-type/create-update`, data, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function remove(data) {
  if (!data) {
    return false;
  }
  return axios
    .post(`${API_URL}/document-type/remove`, data, { withCredentials: true })
    .then(response => response.data);
}

export async function subscribe(cb) {
  return socketService().subscribe("document-type", cb);
}
