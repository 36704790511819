const columns = [
  {
    id: "nr",
    name: "Nr",
    type: "string",
    size: 60
  },
  {
    id: "datetime",
    name: "Datetime",
    type: "datetime",
    size: 60
  },
  {
    id: "email",
    name: "E-mail",
    type: "string",
    size: 60
  },
  {
    id: "message",
    name: "Message",
    type: "string",
    size: 300
  },
  {
    id: "ip",
    name: "IP",
    type: "string",
    size: 60
  },
  {
    id: "locale",
    name: "Locale",
    type: "string"
  },
  {
    id: "user",
    name: "User",
    type: "string"
  },
  {
    id: "showMessage",
    name: "Show",
    type: "icon",
    event: "showMessage",
    icon: "fa fa-eye"
  }
];

export default columns;
