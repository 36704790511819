const columns = [
  {
    id: "nr",
    name: "Nr",
    type: "string",
    isHidden: true,
    size: 60
  },
  {
    id: "language",
    name: "Language",
    type: "string",
    translatable: true
  },
  {
    id: "code",
    name: "Code",
    type: "string",
    translatable: true
  },
  {
    id: "user",
    name: "User",
    type: "string",
    adminOnly: true
  },
  {
    id: "remove",
    name: "Remove",
    type: "icon",
    event: "deleteItem",
    icon: "fa fa-trash"
  }
];

export default columns;
