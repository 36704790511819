/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
import React from "react";
import { TranslationServiceConsumer } from "../context/index";

const withTranslationService = () => (Wrapped) => {
  return (props) => {
    return (
      <TranslationServiceConsumer>
        {
          (translationService) => {
            return <Wrapped
              {...props} translationService={translationService} />;
          }
        }
      </TranslationServiceConsumer>
    );
  }
}

export default withTranslationService;