import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./views/App/App.jsx";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import "assets/scss/animations.scss";
// import "assets/css/material-dashboard-react.css";

import ErrorBoundry from "./components/error-boundry/error-boundry";
import TranslationService from "./services/translation-service";
import AuthService from "./services/auth-service";
import { TranslationServiceProvider } from "./components/context/index";
import { AuthServiceProvider } from "./components/context/index";
import store from "./store";

import "./style.scss";

const translationService = new TranslationService();
const authService = new AuthService();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundry>
      <TranslationServiceProvider value={translationService}>
        <AuthServiceProvider value={authService}>
          <App />
        </AuthServiceProvider>
      </TranslationServiceProvider>
    </ErrorBoundry>
  </Provider>,
  document.getElementById("root")
);
