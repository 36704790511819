import { socketService } from "./socket";
import { API_URL } from "../constants";
import axios from "axios";

import _ from "lodash";

export async function fetch() {
  return axios
    .post(`${API_URL}/language/fetch`, null, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function fetchById(id) {
  if (!id) {
    return false;
  }
  return axios
    .post(`${API_URL}/language/fetch-by-id`, { id }, { withCredentials: true })
    .then(response => response.data);
}

export async function post(collection) {
  if (!_.size(collection)) {
    return false;
  }
  return axios
    .post(
      `${API_URL}/language/create`,
      { collection },
      {
        withCredentials: true
      }
    )
    .then(response => response.data);
}

export async function remove(id) {
  if (!id) {
    return false;
  }
  return axios
    .post(`${API_URL}/language/remove`, { id }, { withCredentials: true })
    .then(response => response.data);
}

export async function subscribe(cb) {
  return socketService().subscribe("language", cb);
}
