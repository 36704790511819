import {
  ACTION_LOGOUT_RES,
  ACTION_SAVE_SESSION,
  ACTION_SET_LOCALE,
  ACTION_SET_TRANSLATE_UI_MODE
} from "../actions/auth";

import moment from "moment";

export default authSession;

const initialState = {
  session: null,
  locale: localStorage.getItem("locale") || "EN",
  translateUiMode: localStorage.getItem("translateUiMode") ? true : false
};

moment.locale(setLocale(localStorage.getItem("locale") || "US"));
function setLocale(locale) {
  moment.locale(setMomentLocale(locale));
  function setMomentLocale(locale) {
    switch (locale) {
      case "GB":
        return "en-gb";
      case "US":
        return "en-us";
      case "EN":
        return "en-us";
      case "LT":
        return "lt";
      case "RU":
        return "ru";
      default:
        return locale.toLowerCase();
    }
  }
}

function authSession(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_SESSION:
      return {
        ...state,
        session: action.payload
      };
    case ACTION_SET_LOCALE:
      setLocale(action.payload);
      return {
        ...state,
        locale: action.payload
      };
    case ACTION_LOGOUT_RES:
      return initialState;
    case ACTION_SET_TRANSLATE_UI_MODE:
      return {
        ...state,
        translateUiMode: !state.translateUiMode
      };
    default:
      return state;
  }
}
