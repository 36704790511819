/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";

import Check from "@material-ui/icons/Check";
import SnackbarContent from "../Snackbar/SnackbarContent.jsx";
import Clearfix from "../Clearfix/Clearfix.jsx";

import ActionNotifications from "../../actions/notifications";
import { connect } from "react-redux";

import _ from "lodash";
import "./style.scss";

class Notifications extends PureComponent {
  handleCopy = item => {
    const { updateMessages, messages } = this.props;
    const input = document.createElement("input");
    input.value = item.copyContent;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    const mess = [...messages];
    _.map(mess, message => {
      message.isCopied = false;
      if (message.title === item.title) {
        message.isCopied = true;
      }
    });
    updateMessages(mess);
  };

  getCopyContent = item => {
    if (item && item.copyContent) {
      return (
        <div className="notifications-container_notification-copy-content layout-row">
          <b>{item.copyContent}</b>
          <span className="notifications-container_notification-copy-button layout-column">
            {this.getCopyBtn(item)}
          </span>
        </div>
      );
    }
    return null;
  };

  getCopyBtn = item => {
    if (item.isCopied) {
      return (
        <div className="animated fadeIn">
          <Check />
        </div>
      );
    }
    return <span onClick={() => this.handleCopy(item)}>Copy</span>;
  };

  handleClose = index => {
    const { updateMessages, messages } = this.props;
    const mess = [...messages];
    if (mess[index]) {
      mess[index].isClosed = true;
    }
    updateMessages(mess);
  };

  getNotifications = messages => {
    if (!messages || !messages.length) {
      return null;
    }
    return _.map(messages, (item, index) => {
      if (item.isClosed) {
        return null;
      }
      let message = (
        <span>
          <b className="notifications-container_notification-title">
            {item.title}
          </b>
          <p>{item.paragraph}</p>
          {this.getCopyContent(item, index)}
        </span>
      );
      if (item.component) {
        message = (
          <span>
            <item.component data={item.data} />
          </span>
        );
      }
      return (
        <SnackbarContent
          key={index}
          classes={{ root: "animated fadeInRight" }}
          message={message}
          onClose={() => this.handleClose(index)}
          close
          color="success"
        />
      );
    });
  };
  render() {
    const { messages } = this.props;
    return (
      <div className="notifications-container">
        {this.getNotifications(messages)}
        <Clearfix />
      </div>
    );
  }
}

const mapStateToProps = ({ notifications }) => {
  return notifications;
};

const mapDispatchToProps = {
  updateMessages: ActionNotifications.update
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
