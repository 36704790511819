import { API_URL } from "../constants";
import axios from "axios";

export async function fetch() {
  return axios
    .post(`${API_URL}/translation-language/fetch`, null, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function post(formData) {
  if (!formData) {
    return false;
  }
  return axios
    .post(`${API_URL}/translation-language/create-update`, formData, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function remove(id) {
  if (!id) {
    return false;
  }
  return axios
    .post(
      `${API_URL}/translation-language/remove`,
      { id },
      { withCredentials: true }
    )
    .then(response => response.data);
}
