/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
import ActionTranslationLanguageForm from "../../actions/translation-language-form";
import Button from "components/AdminCustomButtons/Button.jsx";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import countriesList from "countries-list";

import "./style.scss";
import _ from "lodash";
import { connect } from "react-redux";

function TranslationLanguageForm({ update, post, formData, classes }) {
  const handleFormChange = (e, name) => {
    const value = _.get(e, "target.value");
    const newFormData = { ...formData, [name]: value };
    update(newFormData);
  };

  const handleFormSubmit = () => {
    post();
  };
  const countryToFlag = isoCode => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  const countries = _.map(countriesList.countries, (item, key) => ({
    ...item,
    key,
    title: item.name
  }));
  const handleCountryChange = (e, value) => {
    const code = _.get(value, "key");
    const newFormData = { ...formData, code };
    update(newFormData);
  };
  return (
    <ValidatorForm
      style={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        margin: 0
      }}
      onSubmit={handleFormSubmit}
    >
      <Button
        type="submit"
        color="primary"
        style={{ marginRight: 10, height: "3rem" }}
      >
        <TranslationTextField
          value="TranslationLanguageForm.Submit"
          defaultValue="Submit"
        />
      </Button>
      <div className="translation-language-form_input flex">
        <TextValidator
          label={
            <TranslationTextField
              value="TranslationLanguageForm.Language"
              defaultValue="Language"
            />
          }
          onChange={e => handleFormChange(e, "language")}
          fullWidth={true}
          name="language"
          type="text"
          value={_.get(formData, "language") || ""}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
      </div>
      <div className="translation-language-form_input flex">
        <Autocomplete
          id="country-select-demo"
          style={{ display: "flex", flexGrow: 1 }}
          options={countries}
          onChange={handleCountryChange}
          classes={{
            option: classes.option
          }}
          getOptionLabel={option => option.name}
          renderOption={option => (
            <Fragment>
              <span>{countryToFlag(option.key)}</span>
              {option.name}
            </Fragment>
          )}
          renderInput={params => (
            <TextValidator
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
        />
      </div>
    </ValidatorForm>
  );
}

const mapStateToProps = ({ translationLanguageForm }) => {
  return translationLanguageForm;
};

const mapDispatchToProps = {
  post: ActionTranslationLanguageForm.post,
  update: ActionTranslationLanguageForm.update,
  destroy: ActionTranslationLanguageForm.destroy
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TranslationLanguageForm));
