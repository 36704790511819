import React from "react";
import PropTypes from "prop-types";

const EMPTY_VALUE = "-";

export default function Percent({ isDecimalDisabled, value }) {
  const valueToRender = getValue();
  const title = getTitle();
  return <span title={title}>{valueToRender}</span>;

  function getValue() {
    if (!value) {
      return EMPTY_VALUE;
    }

    return value.toLocaleString({
      style: "percent",
      minimumFractionDigits: isDecimalDisabled ? 0 : 2,
      maximumFractionDigits: isDecimalDisabled ? 0 : 2
    });
  }
  function getTitle() {
    try {
      return value.toFixed(2);
    } catch (e) {
      return EMPTY_VALUE;
    }
  }
}

Percent.propTypes = {
  isDecimalDisabled: PropTypes.bool,
  value: PropTypes.number
};
Percent.defaultProps = {
  isDecimalDisabled: false,
  value: 0
};
