/* eslint no-underscore-dangle: ["error", { "allow": ["_raw", "_reconnection"] }] */
import _ from 'lodash';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';
import { API_URL } from '../constants';

function socketFactory() {
  const io = sailsIOClient(socketIOClient);
  io.sails.url = API_URL;

  io.socket.on('disconnect', () => {
    io.socket._raw.io._reconnection = true;
  });

  return io.socket;
}

const socket = socketFactory();

export function socketService() {
  return {
    subscribe,
  };

  function subscribe(roomName, cb) {
    socket.get(`${API_URL}socket/subscribe?roomName=${roomName}`, () => {
      socket.on('message', (message) => {
        if (message.room === roomName) {
          cb(message);
        }
      });
    });

    return () => {
      unsubscribe(roomName);
      socket.off('message', cb);
    };
  }

  function unsubscribe(roomName) {
    socket.get(`${API_URL}socket/unsubscribe?roomName=${roomName}`);
  }
}

export function socketHelper() {
  return {
    process,
    observe,
  };

  function process(data, message) {
    const collection = _.cloneDeep(data);
    const { type, record } = message;
    const existingRecord = _.find(collection, { id: _.toInteger(record.id) });
    switch (type) {
      case 'create': {
        if (!existingRecord) {
          collection.push({
            ...record,
            socketCreated: true,
          });
        }
        break;
      }
      case 'update': {
        _.assignIn(existingRecord, {
          ...record,
          socketUpdated: true,
        });
        break;
      }
      default:
    }
    return collection;
  }

  function observe(collection, item) {
    switch (item.verb) {
      case 'created':
        collection.push({
          ...item.data,
          socketCreated: true,
        });
        break;
      case 'destroyed':
        _.remove(collection, row => row.id === item.id);
        break;
      case 'updated': {
        const updatedItem = _.find(collection, { id: _.toInteger(item.id) });
        _.assignIn(updatedItem, {
          ...item.data,
          socketUpdated: true,
        });
        break;
      }
      default:
    }

    return collection;
  }
}
