/* eslint-disable react/prop-types */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import WithAuthService from "../../components/hoc/with-auth-service";
import { Redirect } from "react-router-dom";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import image from "assets/img/stamp.jpg";
import "../../assets/jss/material-kit-react/views/loginPage.jsx";

import { connect } from "react-redux";
import ActionAuthSession from "../../actions/auth";

import _ from "lodash";
import "./style.scss";

class LoginPage extends React.Component {
  state = {
    formData: {
      email: "",
      password: ""
    }
  };

  isSubmitActive = () => {
    let isActive = true;
    const { formData } = this.state;
    _.forEach(formData, item => {
      if (!item) {
        isActive = false;
      }
    });
    return isActive;
  };

  handleFormSubmit = async () => {
    const { email, password } = this.state.formData;
    const { login } = this.props;
    login({ email, password });
  };

  handleFormChange = name => event => {
    const { formData } = this.state;
    const newData = { ...formData };
    newData[name] = event.target.value;
    this.setState({ formData: newData });
  };

  getError = () => {
    const { user } = this.props;
    if (user === false) {
      return (
        <div className="login-page_error">
          No such user! Please check out auth data.
        </div>
      );
    }
  };

  render() {
    const { classes, user, ...rest } = this.props;
    const { formData } = this.state;
    if (
      _.get(user, "role") === "admin" ||
      _.get(user, "role") === "superadmin"
    ) {
      return <Redirect to="/admin/dashboard" />;
    }
    if (user) {
      return <Redirect to="/admin/translation-create" />;
    }
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Verify Translation"
          leftLinks={<HeaderLeftLinks />}
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={5}>
                <Card>
                  <ValidatorForm
                    className={classes.form}
                    onSubmit={this.handleFormSubmit}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>
                        <TranslationTextField
                          value="LoginPage.Title"
                          defaultValue="Sign in"
                        />
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <div className="input-container">
                        <TextValidator
                          label={
                            <TranslationTextField
                              value="LoginPage.LabelEmail"
                              defaultValue="Mail address"
                            />
                          }
                          id="email"
                          onChange={this.handleFormChange("email")}
                          fullWidth={true}
                          value={formData.email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required",
                            "Value must be email"
                          ]}
                          type="email"
                        />
                      </div>
                      <div className="input-container">
                        <TextValidator
                          label={
                            <TranslationTextField
                              value="LoginPage.LabelPassword"
                              defaultValue="Password"
                            />
                          }
                          id="password"
                          onChange={this.handleFormChange("password")}
                          fullWidth={true}
                          value={formData.password}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          type="password"
                        />
                      </div>
                      {this.getError()}
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        simple
                        disabled={!this.isSubmitActive()}
                        type="submit"
                        color="primary"
                        size="lg"
                      >
                        <TranslationTextField
                          value="LoginPage.SubmitButton"
                          defaultValue="Get started"
                        />
                      </Button>
                      {/* TODO: Cleanup */}
                      {/* <Link to={"/sign-up"} className={classes.navLink}>
                        Or sign up
                      </Link> */}
                    </CardFooter>
                  </ValidatorForm>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authSession }) => {
  return {
    session: authSession.session,
    user: _.get(authSession.session, "user")
  };
};

const mapDispatchToProps = {
  login: ActionAuthSession.login
};

export default WithAuthService()(
  withStyles(loginPageStyle)(
    connect(mapStateToProps, mapDispatchToProps)(LoginPage)
  )
);
