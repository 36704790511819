import _ from "lodash";
import {
  fetch as fetchMultilanguage,
  remove as removeMultiLanguage
} from "../services/multilanguage-service";

export const PREFIX = "multilanguageList";
export const ACTION_MULTILANGUAGE_LIST_REQ = `${PREFIX}.ACTION_MULTILANGUAGE_LIST_REQ`;
export const ACTION_MULTILANGUAGE_LIST_RES = `${PREFIX}.ACTION_MULTILANGUAGE_LIST_RES`;
export const ACTION_MULTILANGUAGE_LIST_ERR = `${PREFIX}.ACTION_MULTILANGUAGE_LIST_ERR`;
export const ACTION_MULTILANGUAGE_LIST_DESTROY = `${PREFIX}.ACTION_MULTILANGUAGE_LIST_DESTROY`;
export const ACTION_MULTILANGUAGE_LIST_UPDATE = `${PREFIX}.ACTION_MULTILANGUAGE_LIST_UPDATE`;

function ActionMultilanguageList() {
  return {
    destroy,
    fetch,
    remove
  };

  function fetch() {
    return async dispatch => {
      dispatch({ type: ACTION_MULTILANGUAGE_LIST_REQ });
      try {
        const result = await fetchMultilanguage();
        const payload = _.map(result, toListEntity);
        dispatch({ type: ACTION_MULTILANGUAGE_LIST_RES, payload });
      } catch (err) {
        dispatch({ type: ACTION_MULTILANGUAGE_LIST_ERR, payload: err });
      }
    };
  }

  function remove(id) {
    return async dispatch => {
      try {
        await removeMultiLanguage(id);
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION_MULTILANGUAGE_LIST_ERR, payload: err });
      }
    };
  }

  function destroy() {
    return { type: ACTION_MULTILANGUAGE_LIST_DESTROY };
  }

  function toListEntity(item, index) {
    return {
      ...item,
      nr: index + 1,
      flag: `flag-icon flag-icon-${item.locale.toLowerCase()}`
    };
  }
}

export default ActionMultilanguageList();
