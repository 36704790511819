import { post as postDocType } from "../services/multilanguage-service";

export const PREFIX = "multilanguageTypeForm";
export const ACTION_MULTILANGUAGE_FORM_REQ = `${PREFIX}.ACTION_MULTILANGUAGE_FORM_REQ`;
export const ACTION_MULTILANGUAGE_FORM_RES = `${PREFIX}.ACTION_MULTILANGUAGE_FORM_RES`;
export const ACTION_MULTILANGUAGE_FORM_ERR = `${PREFIX}.ACTION_MULTILANGUAGE_FORM_ERR`;
export const ACTION_MULTILANGUAGE_FORM_DESTROY = `${PREFIX}.ACTION_MULTILANGUAGE_FORM_DESTROY`;
export const ACTION_MULTILANGUAGE_FORM_UPDATE = `${PREFIX}.ACTION_MULTILANGUAGE_FORM_UPDATE`;

function ActionMultilanguageForm() {
  return {
    update,
    destroy,
    post
  };

  function post() {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION_MULTILANGUAGE_FORM_REQ });
      try {
        const {
          multilanguageForm: { formData }
        } = getState();
        await postDocType(formData);
        window.location.reload();
      } catch (err) {
        dispatch({ type: ACTION_MULTILANGUAGE_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_MULTILANGUAGE_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_MULTILANGUAGE_FORM_DESTROY };
  }
}

export default ActionMultilanguageForm();
