import { API_URL } from "../constants";
import axios from "axios";

import _ from "lodash";

export async function fetch() {
  return axios
    .post(`${API_URL}/multilanguage/fetch`, null, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function fetchById(id) {
  if (!id) {
    return false;
  }
  return axios
    .post(
      `${API_URL}/multilanguage/fetch-by-id`,
      { id },
      { withCredentials: true }
    )
    .then(response => response.data);
}

export async function post(formData) {
  if (_.isEmpty(formData)) {
    return false;
  }
  return axios
    .post(`${API_URL}/multilanguage/create`, formData, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function postInline({ formData, key, locale }) {
  return axios
    .post(
      `${API_URL}/multilanguage/create-inline`,
      { formData, key, locale },
      {
        withCredentials: true
      }
    )
    .then(response => response.data);
}

export async function remove(id) {
  if (!id) {
    return false;
  }
  return axios
    .post(`${API_URL}/multilanguage/remove`, { id }, { withCredentials: true })
    .then(response => response.data);
}
