/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import { Link } from "react-router-dom";

import image from "assets/img/manuscript.jpg";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { connect } from "react-redux";
import ActionAuthSession from "../../actions/auth";

import _ from "lodash";

class RegistrationPage extends Component {
  state = {
    cardAnimaton: "cardHidden",
    formData: {
      companyName: "",
      email: "",
      password: "",
      confirmPassword: ""
    }
  };

  isSubmitActive = () => {
    let isActive = true;
    const { formData } = this.state;
    _.forEach(formData, item => {
      if (!item) {
        isActive = false;
      }
    });
    return isActive;
  };

  handleFormSubmit = async () => {
    const { password, email, companyName } = this.state.formData;
    const { register } = this.props;
    register({ password, email, companyName });
  };

  handleFormChange = name => event => {
    const { formData } = this.state;
    const newData = { ...formData };
    newData[name] = event.target.value;
    this.setState({ formData: newData });
  };

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== this.state.formData.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("minLength", value => {
      if (value.length < 8) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
    ValidatorForm.removeValidationRule("minLength");
  }

  render() {
    const { classes, ...rest } = this.props;
    const { formData } = this.state;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Verify Translation"
          leftLinks={<HeaderLeftLinks />}
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <ValidatorForm
                    className={classes.form}
                    onSubmit={this.handleFormSubmit}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Create an account</h4>
                    </CardHeader>
                    <CardBody>
                      <div className="input-container">
                        <TextValidator
                          label="Company name"
                          id="companyName"
                          onChange={this.handleFormChange("companyName")}
                          fullWidth={true}
                          value={formData.companyName}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          type="text"
                        />
                      </div>
                      <div className="input-container">
                        <TextValidator
                          label="Email"
                          id="email"
                          onChange={this.handleFormChange("email")}
                          fullWidth={true}
                          value={formData.email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required",
                            "Value must be email"
                          ]}
                          type="email"
                        />
                      </div>
                      <div className="input-container">
                        <TextValidator
                          label="Password"
                          name="password"
                          id="password"
                          onChange={this.handleFormChange("password")}
                          fullWidth={true}
                          value={formData.password}
                          validators={["required", "minLength"]}
                          errorMessages={[
                            "This field is required",
                            "Password must be at least 8 symbols"
                          ]}
                          type="password"
                        />
                      </div>
                      <div className="input-container">
                        <TextValidator
                          label="Repeat password"
                          name="confirmPassword"
                          id="confirmPassword"
                          type="password"
                          onChange={this.handleFormChange("confirmPassword")}
                          fullWidth={true}
                          value={formData.confirmPassword}
                          validators={["isPasswordMatch", "required"]}
                          errorMessages={[
                            "Password mismatch",
                            "This field is required"
                          ]}
                        />
                      </div>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        simple
                        disabled={!this.isSubmitActive()}
                        type="submit"
                        color="primary"
                        size="lg"
                      >
                        Create
                      </Button>
                      <Link to={"/sign-in"} className={classes.navLink}>
                        Or Sign in
                      </Link>
                    </CardFooter>
                  </ValidatorForm>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authSession }) => {
  return { ...authSession };
};

const mapDispatchToProps = {
  register: ActionAuthSession.register
};

export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistrationPage)
);
