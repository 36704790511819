import {
  ACTION_FEEDBACK_FORM_REQ,
  ACTION_FEEDBACK_FORM_RES,
  ACTION_FEEDBACK_FORM_ERR,
  ACTION_FEEDBACK_FORM_UPDATE,
  ACTION_FEEDBACK_FORM_DESTROY,
  ACTION_FEEDBACK_FORM_SUCCESS
} from "../actions/feedback-form";

import _ from "lodash";

const initialState = {
  formData: {},
  error: false,
  loading: false
};

const feedbackForm = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FEEDBACK_FORM_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_FEEDBACK_FORM_RES:
      return {
        ...state,
        loading: false,
        formData: action.payload
      };
    case ACTION_FEEDBACK_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: _.get(action.payload, "message")
      };
    case ACTION_FEEDBACK_FORM_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_FEEDBACK_FORM_UPDATE:
      return {
        ...state,
        formData: action.payload,
        loading: false
      };
    case ACTION_FEEDBACK_FORM_DESTROY:
      return {
        formData: {},
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default feedbackForm;
