/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { ActionFeedbackForm, ActionFeedbackById } from "../../actions/index";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import _ from "lodash";

import "./styles.scss";

function FeedbackView({ classes, data, feedbackId, fetch }) {
  useEffect(() => {
    if (feedbackId) {
      fetch(feedbackId);
    }
  }, [feedbackId]);
  return (
    <div className="flex layout-column feedback-form">
      <Card className="animated fadeIn">
        <ValidatorForm className={classes.form}>
          <CardHeader color="primary" className={classes.cardHeader}>
            <h4>
              <TranslationTextField
                value="FeedbackPage.FormViewTitle"
                defaultValue="Feedback message"
              />
            </h4>
          </CardHeader>
          <CardBody>
            <div className="input-container">
              <TextValidator
                label={
                  <TranslationTextField
                    value="LoginPage.LabelEmail"
                    defaultValue="Mail address"
                  />
                }
                id="email"
                onChange={() => false}
                fullWidth={true}
                value={_.get(data, "email") || ""}
                validators={["required", "isEmail"]}
                errorMessages={[
                  "This field is required",
                  "Value must be email"
                ]}
                type="email"
              />
            </div>
            <div className="input-container flex layout-column">
              <TextareaAutosize
                rowsMin={5}
                className="feedback-form_textarea"
                aria-label="maximum height"
                placeholder="Message"
                onChange={() => false}
                value={_.get(data, "message") || ""}
                style={{
                  borderColor: "rgba(0,0,0,0.4)"
                }}
              />
            </div>
          </CardBody>
        </ValidatorForm>
      </Card>
    </div>
  );
}

const mapStateToProps = ({ feedbackById }) => {
  return feedbackById;
};

const mapDispatchToProps = {
  post: ActionFeedbackForm.post,
  update: ActionFeedbackForm.update,
  fetch: ActionFeedbackById.fetch
};

export default withStyles(componentsStyle)(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackView)
);
