/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/AdminGrid/GridItem.jsx";
import GridContainer from "components/AdminGrid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardBody from "components/AdminCard/CardBody.jsx";
import DocumentTypeForm from "../../components/document-type-from/document-type-form.jsx";
import Modal from "../../components/Modal/Modal.jsx";
import Button from "components/AdminCustomButtons/Button.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import ActionDocumentTypeForm from "../../actions/document-type-form";
import ActionDocumentTypeList from "../../actions/document-type-list";
import { ActionModal } from "../../actions/index";

import { connect } from "react-redux";

import _ from "lodash";
import columns from "./columns";
import "./style.scss";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function DocumentTypesPage({
  fetch,
  update,
  openModal,
  post,
  collection,
  remove,
  modalId,
  open,
  classes,
  loading,
  formData,
  user
}) {
  useEffect(() => {
    fetch();
  }, []);
  const [isActive, setIsActive] = useState(false);

  const handleFormActive = () => {
    setIsActive(!isActive);
  };

  const handleFormChange = formData => {
    update(formData);
  };

  const handleFormSubmit = () => {
    post();
    openModal({ open: false, modalId: "documentTypeForm" });
  };

  const handleTableEvent = (eventId, id) => {
    const docType = _.find(collection, { id });
    switch (eventId) {
      case "deleteItem":
        remove({ id });
        break;
      case "editItem":
        update(docType);
        openModal({ open: true, modalId: "documentTypeForm" });
        break;
      default:
        break;
    }
  };

  const handleModal = () => {
    openModal({ open: !open, modalId });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className="flex layout-row">
          <Button
            type="button"
            color="primary"
            onClick={handleFormActive}
            style={{ marginRight: 10, height: "3rem" }}
          >
            <TranslationTextField
              value="DocumentTypePage.NewDocumentType"
              defaultValue="New document type"
            />
          </Button>
          {isActive ? (
            <DocumentTypeForm
              formData={formData}
              onChange={handleFormChange}
              onSubmit={handleFormSubmit}
            />
          ) : null}
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              <TranslationTextField
                value="DocumentTypePage.DocumentTypes"
                defaultValue="Document types"
              />
            </h4>
          </CardHeader>
          <CardBody>
            <Table
              isLoading={loading}
              tableHeaderColor="primary"
              tableHead={columns}
              tableData={collection}
              onTableEvent={handleTableEvent}
              user={user}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Modal
        onClose={handleModal}
        open={open && modalId === "documentTypeForm"}
      >
        <div className="modal_document-type-form">
          <DocumentTypeForm
            formData={formData}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
            isEdit={true}
          />
        </div>
      </Modal>
    </GridContainer>
  );
}

const mapStateToProps = ({
  documentTypeForm,
  documentTypeList,
  modal,
  authSession
}) => {
  return {
    ...documentTypeForm,
    collection: documentTypeList.collection,
    loading: documentTypeList.loading,
    open: modal.open,
    modalId: modal.modalId,
    user: _.get(authSession.session, "user")
  };
};

const mapDispatchToProps = {
  post: ActionDocumentTypeForm.post,
  update: ActionDocumentTypeForm.update,
  destroy: ActionDocumentTypeForm.destroy,
  fetch: ActionDocumentTypeList.fetch,
  remove: ActionDocumentTypeList.remove,
  openModal: ActionModal.update
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DocumentTypesPage)
);
