/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import LoginPage from "views/LoginPage/LoginPage.jsx";
import HomePage from "views/HomePage/HomePage.jsx";
import AboutUsPage from "views/AboutUsPage/AboutUsPage";
import FeedbackPage from "views/FeedbackPage/FeedbackPage";
import Admin from "layouts/Admin.jsx";
import RegistrationPage from "views/Registration/RegistrationPage.jsx";

import WithAuthService from "../../components/hoc/with-auth-service";
import Modal from "../../components/Modal/Modal.jsx";
import MultilanguageCreate from "containers/multilanguage-create/MultilanguageCreate";
import { ActionModal } from "../../actions/index";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import ActionAuthSession from "../../actions/auth";
import ActionMultilanguageKeys from "../../actions/multilanguage-keys";
import LoadingPage from "../LoadingPage/LoadingPage.jsx";

import _ from "lodash";

export const history = createBrowserHistory();

function App({
  getSession,
  fetchMultilanguageKeys,
  user,
  openModal,
  open,
  modalId,
  createSession
}) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    getSession();
    fetchMultilanguageKeys();
    createSession();
    setTimeout(() => setIsMounted(true), 2500);
  }, []);

  const getAdminRoute = () => {
    if (user) {
      return <Route path="/admin" component={Admin} />;
    }
    return <Redirect to="/sign-in" />;
  };

  const handleMultiEditTranslationModal = () => {
    openModal({ open: !open, modalId: "translationsEditForm" });
  };

  if (!isMounted) {
    return <LoadingPage />;
  }
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/about" component={AboutUsPage} />
          <Route path="/feedback" component={FeedbackPage} />
          <Route path="/sign-in" component={LoginPage} />
          {_.get(user, "role") === "admin" ||
          _.get(user, "role") === "superadmin" ? (
            <Route path="/sign-up" component={RegistrationPage} />
          ) : null}
          {getAdminRoute()}
        </Switch>
      </Router>
      <Modal
        onClose={handleMultiEditTranslationModal}
        open={open && modalId === "translationsEditForm"}
        style={{ minWidth: "50rem" }}
      >
        <MultilanguageCreate isEdit />
      </Modal>
    </>
  );
}

const mapStateToProps = ({ authSession, modal }) => {
  return {
    session: authSession.session,
    user: _.get(authSession.session, "user"),
    open: modal.open,
    modalId: modal.modalId
  };
};

const mapDispatchToProps = {
  getSession: ActionAuthSession.getSession,
  fetchMultilanguageKeys: ActionMultilanguageKeys.fetch,
  openModal: ActionModal.update,
  createSession: ActionAuthSession.createSession
};

export default WithAuthService()(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
