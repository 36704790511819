import {
  ACTION_TRANSLATIONS_LIST_REQ,
  ACTION_TRANSLATIONS_LIST_RES,
  ACTION_TRANSLATIONS_LIST_ERR,
  ACTION_TRANSLATIONS_LIST_UPDATE,
  ACTION_TRANSLATIONS_LIST_DESTROY
} from "../actions/translations-list";

const initialState = {
  collection: null,
  error: false,
  loading: false
};

const translationsList = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TRANSLATIONS_LIST_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_TRANSLATIONS_LIST_RES:
      return {
        ...state,
        loading: false,
        collection: action.payload
      };
    case ACTION_TRANSLATIONS_LIST_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_TRANSLATIONS_LIST_UPDATE:
      return {
        ...state,
        collection: action.payload,
        loading: true
      };
    case ACTION_TRANSLATIONS_LIST_DESTROY:
      return initialState;
    default:
      return state;
  }
};

export default translationsList;
