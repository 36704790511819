/*eslint-disable*/
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import LanguageDropdown from "containers/language-dropdown/LanguageDropdown";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import { connect } from "react-redux";
import ActionAuthSession from "../../actions/auth";

import 'flag-icon-css/css/flag-icon.css'

const HeaderLinks = ({
  classes,
  logout,
  user,
}) => {

  const getAuthButton = () => {
    if (!user) {
      return (
        <ListItem className={classes.listItem}>
          <Link to={"/sign-in"} className={classes.navLink}>
            <TranslationTextField value="HeaderLinks.Login" defaultValue={"Log in"} />
          </Link>
        </ListItem>
      )
    }
    return (
      <ListItem className={classes.listItem}>
        <div onClick={handleLogOut} className={classes.navLink}>
          <TranslationTextField value="HeaderLinks.Logout" defaultValue={"Log out"} />
        </div>
      </ListItem>
    );
  }

  const handleLogOut = () => {
    logout();
  }

  const icon = ('fas fa-language');
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <LanguageDropdown />
      </ListItem>
      {getAuthButton()}
    </List>
  );
}

const mapStateToProps = ({ authSession }) => {
  return {
    session: authSession.session,
    user: _.get(authSession.session, "user")
  };
};

const mapDispatchToProps = {
  logout: ActionAuthSession.logout
};

export default withStyles(headerLinksStyle)(
  connect(mapStateToProps, mapDispatchToProps)(HeaderLinks));
