/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import GridItem from "components/AdminGrid/GridItem.jsx";
import GridContainer from "components/AdminGrid/GridContainer.jsx";
import LanguageForm from "components/language-form/LanguageForm";

import ActionLanguageForm from "../../actions/language-form";
import ActionLanguageList from "../../actions/language-list";

import { connect } from "react-redux";

function LanguageCreateUpdate({
  collection,
  update,
  post,
  fetchLanguages,
  addedLanguages
}) {
  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleFormChange = collection => {
    update(collection);
  };

  const handleFormSubmit = () => {
    post();
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <LanguageForm
          collection={collection}
          onChange={handleFormChange}
          onSubmit={handleFormSubmit}
          addedLanguages={addedLanguages}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = ({ languageForm, languageList }) => {
  return {
    ...languageForm,
    addedLanguages: languageList.collection
  };
};

const mapDispatchToProps = {
  post: ActionLanguageForm.post,
  update: ActionLanguageForm.update,
  destroy: ActionLanguageForm.destroy,
  fetchLanguages: ActionLanguageList.fetch
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageCreateUpdate);
