import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "moment/min/locales";
import MomentUtils from "@date-io/moment";

import {
  DatePicker as DatePickerLib,
  MuiPickersUtilsProvider
} from "material-ui-pickers";
import { FixedScaleAxis } from "chartist";

function DatePicker({ date, onChange }) {
  const handleDateChange = date => {
    onChange(date);
  };

  const locale = localStorage.getItem("locale") || "EN";
  const setMomentLocale = () => {
    switch (locale) {
      case "GB":
        return "en-gb";
      case "US":
        return "en-us";
      case "EN":
        return "en-us";
      case "LT":
        return "lt";
      case "RU":
        return "ru";
      default:
        return locale.toLowerCase();
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePickerLib
        format={moment(date)
          .locale(setMomentLocale())
          .format("L")}
        fullWidth={true}
        value={date}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  date: PropTypes.any,
  onChange: PropTypes.func
};
DatePicker.defaultProps = {
  date: moment(),
  onChange: () => FixedScaleAxis
};

export default DatePicker;
