import React from "react";
import PropTypes from "prop-types";

const EMPTY_VALUE = "-";

export default function Currency({ isDecimalDisabled, value, isSymbol }) {
  const valueToRender = getValue();
  const title = getTitle();
  return <span title={title}>{valueToRender}</span>;

  function getValue() {
    if (!value) {
      return EMPTY_VALUE;
    }
    if (isSymbol) {
      return new Intl.NumberFormat("lt-LT", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: isDecimalDisabled === false ? 2 : 0,
        maximumFractionDigits: isDecimalDisabled === false ? 2 : 0
      }).format(value);
    }
    return new Intl.NumberFormat({
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: isDecimalDisabled === false ? 2 : 0,
      maximumFractionDigits: isDecimalDisabled === false ? 2 : 0
    }).format(value);
  }
  function getTitle() {
    try {
      return value.toFixed(2);
    } catch (e) {
      return EMPTY_VALUE;
    }
  }
}

Currency.propTypes = {
  isDecimalDisabled: PropTypes.bool,
  value: PropTypes.number,
  isSymbol: PropTypes.bool
};
Currency.defaultProps = {
  isDecimalDisabled: true,
  value: 0,
  isSymbol: false
};
