import _ from "lodash";
// import { socketHelper } from "../services/socket";

import {
  fetch as fetchDocTypes,
  remove as removeDocType
} from "../services/document-type-service";

export const PREFIX = "documentTypeList";
export const ACTION_DOCUMENT_TYPE_LIST_REQ = `${PREFIX}.ACTION_DOCUMENT_TYPE_LIST_REQ`;
export const ACTION_DOCUMENT_TYPE_LIST_RES = `${PREFIX}.ACTION_DOCUMENT_TYPE_LIST_RES`;
export const ACTION_DOCUMENT_TYPE_LIST_ERR = `${PREFIX}.ACTION_DOCUMENT_TYPE_LIST_ERR`;
export const ACTION_DOCUMENT_TYPE_LIST_DESTROY = `${PREFIX}.ACTION_DOCUMENT_TYPE_LIST_DESTROY`;
export const ACTION_DOCUMENT_TYPE_LIST_UPDATE = `${PREFIX}.ACTION_DOCUMENT_TYPE_LIST_UPDATE`;

function ActionDocumentTypeList() {
  return {
    destroy,
    fetch,
    remove
  };

  function fetch() {
    return async dispatch => {
      dispatch({ type: ACTION_DOCUMENT_TYPE_LIST_REQ });
      try {
        const result = await fetchDocTypes();
        const ordered = _.orderBy(result, ["label"], ["asc"]);
        const payload = _.map(ordered, toListEntity);
        dispatch({ type: ACTION_DOCUMENT_TYPE_LIST_RES, payload });
      } catch (err) {
        dispatch({ type: ACTION_DOCUMENT_TYPE_LIST_ERR, payload: err });
      }
    };
  }

  function destroy() {
    return { type: ACTION_DOCUMENT_TYPE_LIST_DESTROY };
  }

  function remove(id) {
    return async dispatch => {
      try {
        await removeDocType(id);
      } catch (err) {
        dispatch({ type: ACTION_DOCUMENT_TYPE_LIST_ERR, payload: err });
      }
    };
  }

  function toListEntity(item, index) {
    return {
      nr: index + 1,
      id: item.id,
      label: item.label,
      user: _.get(item.user, "email") || "-"
    };
  }
}

export default ActionDocumentTypeList();
