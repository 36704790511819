/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import ActionLanguageList from "../../actions/language-list";
import ActionAuth from "../../actions/auth";

import { connect } from "react-redux";
import _ from "lodash";

import "./style.scss";

function LanguageDropdown({
  collection,
  classes,
  fetch,
  authSession,
  setLocale
}) {
  useEffect(() => {
    fetch();
  }, []);

  const handleClick = item => {
    localStorage.setItem("locale", item.key);
    setLocale(item.key);
  };

  if (!_.size(collection)) {
    return null;
  }

  return (
    <CustomDropdown
      noLiPadding
      buttonText={authSession.locale}
      buttonProps={{
        className: classes.navLink,
        color: "transparent"
      }}
      buttonIcon={`flag-icon flag-icon-${authSession.locale.toLowerCase()} flag-icon dropdown-icon`}
      className="flex layout-row"
      dropdownList={_.map(collection, (item, index) => (
        <div
          key={index}
          onClick={() => handleClick(item)}
          className={classes.dropdownLink}
          style={{ display: "flex" }}
        >
          <i
            className={`flag-icon flag-icon-${item.key.toLowerCase()} flag-icon dropdown-icon`}
          />
          <TranslationTextField value={item.key} defaultValue={item.key} />
        </div>
      ))}
    />
  );
}

const mapStateToProps = ({ languageList, authSession }) => {
  return { ...languageList, authSession };
};

const mapDispatchToProps = {
  fetch: ActionLanguageList.fetch,
  destroy: ActionLanguageList.destroy,
  setLocale: ActionAuth.setLocale
};

export default withStyles(headerLinksStyle)(
  connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown)
);
