import moment from "moment";
import TranslationService from "../services/translation-service";
import TranslationNotification from "containers/translation-notification/TranslationNotification";
const translationService = new TranslationService();

export const PREFIX = "translationForm";
export const ACTION_TRANSLATION_FORM_REQ = `${PREFIX}.ACTION_TRANSLATION_FORM_REQ`;
export const ACTION_TRANSLATION_FORM_RES = `${PREFIX}.ACTION_TRANSLATION_FORM_RES`;
export const ACTION_TRANSLATION_FORM_ERR = `${PREFIX}.ACTION_TRANSLATION_FORM_ERR`;
export const ACTION_TRANSLATION_FORM_DESTROY = `${PREFIX}.ACTION_TRANSLATION_FORM_DESTROY`;
export const ACTION_TRANSLATION_FORM_UPDATE = `${PREFIX}.ACTION_TRANSLATION_FORM_UPDATE`;

function ActionTranslationForm() {
  return {
    update,
    destroy,
    post
  };

  function post() {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION_TRANSLATION_FORM_REQ });
      try {
        const { translationForm, notifications } = getState();
        const messages = notifications.messages;
        const postData = toPostEntity(translationForm.formData);
        const translation = await translationService.post(postData);
        const message = {
          isCopied: false,
          isClosed: false,
          component: TranslationNotification,
          data: {
            translationId: translation.translationId,
            translationDate: translation.translationDate,
            translatedTo: translation.translatedTo
          }
        };
        if (translation) {
          const newMessages = [...messages, message];
          dispatch({
            type: "notifications.ACTION_NOTIFICATIONS_UPDATE",
            payload: newMessages
          });
        }
        dispatch({ type: "modal.ACTION_MODAL_DESTROY" });
        dispatch({
          type: ACTION_TRANSLATION_FORM_UPDATE,
          payload: {
            translationAgency: translation.translationAgency,
            translationDate: moment(),
            documentType: "",
            documentNumber: "",
            translatedFrom: translation.translatedFrom,
            translatedTo: translation.translatedTo
          }
        });
      } catch (err) {
        dispatch({ type: ACTION_TRANSLATION_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_TRANSLATION_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_TRANSLATION_FORM_DESTROY };
  }

  function toPostEntity(item) {
    return {
      translationDate: item.translationDate.format("YYYY-MM-DD"),
      translationAgency: item.translationAgency,
      documentType: item.documentType,
      documentNumber: item.documentNumber,
      translatedFrom: item.translatedFrom,
      translatedTo: item.translatedTo
    };
  }
}

export default ActionTranslationForm();
