/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
import React from "react";
import { AuthServiceConsumer } from "../context/index";

const withAuthService = () => (Wrapped) => {
  return (props) => {
    return (
      <AuthServiceConsumer>
        {
          (authService) => {
            return <Wrapped
              {...props} authService={authService} />;
          }
        }
      </AuthServiceConsumer>
    );
  }
}

export default withAuthService;