import {
  ACTION_REGISTER_REQ,
  ACTION_REGISTER_RES,
  ACTION_REGISTER_ERR
} from "../actions/auth";

export default authRegister;

const initialState = {
  loading: false,
  error: false
};

function authRegister(state = initialState, action) {
  switch (action.type) {
    case ACTION_REGISTER_REQ:
      return {
        ...state,
        loading: true,
        error: false,
        user: {}
      };
    case ACTION_REGISTER_RES:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload
      };
    case ACTION_REGISTER_ERR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
