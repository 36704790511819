import { API_URL } from "../constants";
import axios from "axios";

export async function fetchUserStatystics() {
  return axios
    .post(`${API_URL}/session/fetch`, null, {
      withCredentials: true
    })
    .then(response => response.data);
}

export async function fetchVerificationStatystics() {
  return axios
    .post(`${API_URL}/verify-statystics/fetch`, null, {
      withCredentials: true
    })
    .then(response => response.data);
}
