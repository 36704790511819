/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/AdminGrid/GridItem.jsx";
import GridContainer from "components/AdminGrid/GridContainer.jsx";
import Button from "components/AdminCustomButtons/Button.jsx";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardBody from "components/AdminCard/CardBody.jsx";
import CardFooter from "components/AdminCard/CardFooter.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "components/DatePicker/DatePicker.jsx";

import ActionTranslationForm from "../../actions/translation-form";
import ActionDocumentTypeList from "../../actions/document-type-list";
import ActionTranslationLanguageList from "../../actions/translation-language-list";
import { connect, useDispatch } from "react-redux";

import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from "react-material-ui-form-validator";

import _ from "lodash";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import { styles } from "./style";
import "./style.scss";

function TranslationForm({
  update,
  formData,
  post,
  classes,
  documentTypeOptions,
  fetchDocTypes,
  user,
  fetchLanguages,
  langOptions
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchDocTypes();
    fetchLanguages();
    update({
      ...formData,
      translationAgency: user.companyName
    });
    return () => {
      ValidatorForm.removeValidationRule("maxLength");
      ValidatorForm.removeValidationRule("langSelect");
      ValidatorForm.removeValidationRule("docTypeSelect");
      ValidatorForm.removeValidationRule("langSelect");
    };
  }, [dispatch]);

  useEffect(() => {
    ValidatorForm.addValidationRule("maxLength", value => {
      if (_.size(value) > 150) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("langSelect", value => {
      if (!_.find(langOptions, { value })) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("docTypeSelect", value => {
      if (value && !_.find(documentTypeOptions, { value })) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("langSelect", value => {
      if (value && !_.find(langOptions, { value })) {
        return false;
      }
      return true;
    });
  }, [dispatch, documentTypeOptions]);

  const handleFormChange = name => event => {
    formData[name] = event.target.value;
    update(formData);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    post();
  };

  const handleDateChange = date => {
    formData.translationDate = date;
    update(formData);
  };

  const handleLanguageSwitch = () => {
    const newFormData = _.cloneDeep(formData);
    newFormData.translatedFrom = formData.translatedTo;
    newFormData.translatedTo = formData.translatedFrom;
    update(newFormData);
  };

  const isSubmitActive = () => {
    let isActive = true;
    _.forEach(formData, item => {
      if (!item) {
        isActive = false;
      }
    });
    return isActive;
  };

  return (
    <GridContainer>
      <ValidatorForm
        className={classes.form}
        onSubmit={handleFormSubmit}
        style={{ marginBottom: 0, display: "flex", flexGrow: 1 }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ marginBottom: 0 }}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <TranslationTextField
                  value="TranslationPage.FormTitle"
                  defaultValue="Add translation"
                />
              </h4>
              <p className={classes.cardCategoryWhite}>
                <TranslationTextField
                  value="TranslationPage.FormSubTitle"
                  defaultValue="Enter following information and submit"
                />
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="input-container">
                    <DatePicker
                      date={formData.translationDate}
                      onChange={handleDateChange}
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="input-container">
                    <TextValidator
                      disabled={true}
                      fullWidth={true}
                      value={formData.translationAgency}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="input-container">
                    <SelectValidator
                      label={
                        <TranslationTextField
                          value="TranslationPage.DocumentTypeLabel"
                          defaultValue="Document type"
                        />
                      }
                      validators={["docTypeSelect"]}
                      errorMessages={["Invalid data"]}
                      onChange={handleFormChange("documentType")}
                      fullWidth={true}
                      value={formData.documentType}
                    >
                      {_.orderBy(documentTypeOptions, ["value"], ["asc"]).map(
                        option => (
                          <MenuItem key={option.id} value={option.value}>
                            <TranslationTextField
                              value={option.value}
                              defaultValue={option.value}
                              hideMinus
                            />
                          </MenuItem>
                        )
                      )}
                    </SelectValidator>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="input-container">
                    <TextValidator
                      label={
                        <TranslationTextField
                          value="TranslationPage.DocumentNumberLabel"
                          defaultValue="Document number"
                        />
                      }
                      onChange={handleFormChange("documentNumber")}
                      fullWidth={true}
                      name="documentNumber"
                      type="text"
                      value={formData.documentNumber || ""}
                      validators={["required", "maxLength"]}
                      errorMessages={[
                        "This field is required",
                        "Value is too long"
                      ]}
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="input-container language">
                    <SelectValidator
                      label={
                        <TranslationTextField
                          value="TranslationPage.TranslatedFrom"
                          defaultValue="Translated from"
                        />
                      }
                      validators={["langSelect"]}
                      errorMessages={["Invalid data"]}
                      onChange={handleFormChange("translatedFrom")}
                      fullWidth={true}
                      value={formData.translatedFrom}
                    >
                      {_.orderBy(langOptions, ["label"], ["asc"]).map(
                        option => (
                          <MenuItem key={option.id} value={option.value}>
                            <TranslationTextField
                              value={option.label}
                              defaultValue={option.label}
                              hideMinus
                            />
                          </MenuItem>
                        )
                      )}
                    </SelectValidator>
                    <i
                      className="fa fa-arrows-h language-change-btn"
                      aria-hidden="true"
                      onClick={handleLanguageSwitch}
                      title="Swap languages"
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="input-container">
                    <SelectValidator
                      label={
                        <TranslationTextField
                          value="TranslationPage.TranslatedTo"
                          defaultValue="Translated To"
                        />
                      }
                      validators={["langSelect"]}
                      errorMessages={["Invalid data"]}
                      onChange={handleFormChange("translatedTo")}
                      fullWidth={true}
                      value={formData.translatedTo}
                    >
                      {_.orderBy(langOptions, ["label"], ["asc"]).map(
                        option => (
                          <MenuItem key={option.id} value={option.value}>
                            <TranslationTextField
                              value={option.label}
                              defaultValue={option.label}
                              hideMinus
                            />
                          </MenuItem>
                        )
                      )}
                    </SelectValidator>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                type="submit"
                color="primary"
                disabled={!isSubmitActive()}
              >
                <TranslationTextField
                  value="Button.Sumbit"
                  defaultValue="Submit"
                />
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </ValidatorForm>
    </GridContainer>
  );
}

const mapStateToProps = ({
  translationForm,
  authSession,
  documentTypeList,
  multilanguageKeys,
  translationLanguageList
}) => {
  return {
    ...translationForm,
    documentTypeOptions: _.map(documentTypeList.collection, item => {
      return {
        id: item.id,
        value: item.label
      };
    }),
    user: _.get(authSession.session, "user"),
    multilanguageKeys: _.get(multilanguageKeys, "collection"),
    locale: _.get(authSession, "locale"),
    langOptions: _.get(translationLanguageList, "collection")
  };
};

const mapDispatchToProps = {
  post: ActionTranslationForm.post,
  update: ActionTranslationForm.update,
  destroy: ActionTranslationForm.destroy,
  fetchDocTypes: ActionDocumentTypeList.fetch,
  fetchLanguages: ActionTranslationLanguageList.fetch
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TranslationForm)
);
