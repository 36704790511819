import { post as postFeedback } from "../services/feedback";

export const PREFIX = "feedbackForm";
export const ACTION_FEEDBACK_FORM_REQ = `${PREFIX}.ACTION_FEEDBACK_FORM_REQ`;
export const ACTION_FEEDBACK_FORM_RES = `${PREFIX}.ACTION_FEEDBACK_FORM_RES`;
export const ACTION_FEEDBACK_FORM_SUCCESS = `${PREFIX}.ACTION_FEEDBACK_FORM_SUCCESS`;
export const ACTION_FEEDBACK_FORM_ERR = `${PREFIX}.ACTION_FEEDBACK_FORM_ERR`;
export const ACTION_FEEDBACK_FORM_DESTROY = `${PREFIX}.ACTION_FEEDBACK_FORM_DESTROY`;
export const ACTION_FEEDBACK_FORM_UPDATE = `${PREFIX}.ACTION_FEEDBACK_FORM_UPDATE`;

function ActionFeedbackForm() {
  return {
    update,
    destroy,
    post
  };

  function post() {
    return async (dispatch, getState) => {
      dispatch({ type: ACTION_FEEDBACK_FORM_REQ });
      try {
        const {
          feedbackForm: { formData }
        } = getState();
        const payload = await postFeedback(formData);
        dispatch({ type: ACTION_FEEDBACK_FORM_DESTROY });
        dispatch({ type: ACTION_FEEDBACK_FORM_SUCCESS, payload });
      } catch (err) {
        dispatch({ type: ACTION_FEEDBACK_FORM_ERR, payload: err });
      }
    };
  }

  function update(payload) {
    return {
      type: ACTION_FEEDBACK_FORM_UPDATE,
      payload
    };
  }

  function destroy() {
    return { type: ACTION_FEEDBACK_FORM_DESTROY };
  }
}

export default ActionFeedbackForm();
