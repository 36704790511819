/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import Table from "components/Table/Table";
import Card from "components/AdminCard/Card.jsx";
import CardHeader from "components/AdminCard/CardHeader.jsx";
import CardBody from "components/AdminCard/CardBody.jsx";
import Loader from "components/Loader/Loader";
import columns from "./columns";
import TranslationTextField from "../../containers/translated-text-field/TranslatedTextField";

import ActionMultilanguageList from "../../actions/multilanguage-list";

import { connect } from "react-redux";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const MultilanguageList = ({ classes, loading, collection, fetch, remove }) => {
  useEffect(() => {
    fetch();
  }, []);

  const handleTableEvent = (eventId, id) => {
    switch (eventId) {
      case "deleteItem":
        remove(id);
        break;
      default:
        break;
    }
  };

  if (!collection || loading) {
    return <Loader />;
  }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          <TranslationTextField
            value="MultilanguageList.Title"
            defaultValue="Multilanguage translations"
          />
        </h4>
      </CardHeader>
      <CardBody>
        <Table
          isLoading={loading}
          tableHeaderColor="primary"
          tableHead={columns}
          tableData={collection}
          onTableEvent={handleTableEvent}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ multilanguageList }) => {
  return { ...multilanguageList };
};

const mapDispatchToProps = {
  fetch: ActionMultilanguageList.fetch,
  remove: ActionMultilanguageList.remove,
  destroy: ActionMultilanguageList.destroy
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MultilanguageList)
);
