import {
  ACTION_DOCUMENT_TYPE_LIST_REQ,
  ACTION_DOCUMENT_TYPE_LIST_RES,
  ACTION_DOCUMENT_TYPE_LIST_ERR,
  ACTION_DOCUMENT_TYPE_LIST_DESTROY
} from "../actions/document-type-list";

const initialState = {
  collection: [],
  error: false,
  loading: false,
};

const documentTypeList = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_DOCUMENT_TYPE_LIST_REQ:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ACTION_DOCUMENT_TYPE_LIST_RES:
      return {
        ...state,
        loading: false,
        collection: action.payload
      };
    case ACTION_DOCUMENT_TYPE_LIST_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_DOCUMENT_TYPE_LIST_DESTROY:
      return {
        collection: [],
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default documentTypeList;
