import {
  ACTION_DOCUMENT_TYPE_FORM_REQ,
  ACTION_DOCUMENT_TYPE_FORM_RES,
  ACTION_DOCUMENT_TYPE_FORM_ERR,
  ACTION_DOCUMENT_TYPE_FORM_UPDATE,
  ACTION_DOCUMENT_TYPE_FORM_DESTROY
} from "../actions/document-type-form";

const initialState = {
  formData: {
    label: ""
  },
  error: false,
  loading: false
};

const documentTypeForm = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_DOCUMENT_TYPE_FORM_REQ:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_DOCUMENT_TYPE_FORM_RES:
      return {
        ...state,
        loading: false,
        formData: action.payload
      };
    case ACTION_DOCUMENT_TYPE_FORM_ERR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_DOCUMENT_TYPE_FORM_UPDATE:
      return {
        ...state,
        formData: action.payload,
        loading: false
      };
    case ACTION_DOCUMENT_TYPE_FORM_DESTROY:
      return {
        formData: {
          label: ""
        },
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default documentTypeForm;
